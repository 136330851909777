/* eslint-disable no-console */
// import DatePicker from 'react-datepicker';
// import lt from '../../../assets/dlt.svg';
// import gt from '../../../assets/dgt.svg';
// import Cal from '../../../assets/calendar.svg';
import RefreshIcon from '@mui/icons-material/Refresh';
import CloseIcon from '@mui/icons-material/Close';
import React, { useEffect, useRef, useState } from 'react';
// import DropDown from '../../../components/DropDown';
import TextField from '../../../components/TextField';
import Search from '../../../assets/search.svg';
import X from '../../../assets/x.svg';
import Pill from '../../../assets/pill.svg';
import Table from '../../../components/Table';
import Arrow from '../../../assets/arrow.svg';
import ADetails from '../../../assets/actionDetails.svg';
import { Link, useNavigate } from 'react-router-dom';
import Button from '../../../components/Button';
import { changeRequest, modification } from '../../../api/modification';
import { useSelector } from 'react-redux';
import moment from 'moment';
import Loader from '../../../components/Loader';
// import { format } from 'date-fns';
import spin from '../../../assets/Eclipse-1s-200px.gif';
import Pagination from '@mui/material/Pagination';

function Modification () {
    const [newC, setnewC] = useState(['-', '-']);
    const [cancelC, setcancelC] = useState(['-', '-']);
    const [modifyC, setmodifyC] = useState(['-', '-']);
    const [refillC, setrefillC] = useState(['-', '-']);
    const [profileC, setprofileC] = useState(['-', '-']);
    const { userInfo } = useSelector(state => state.userInfo);
    // const [Datess, setDatess] = useState(new Date(userInfo.dates));
    // const [isOpen, setIsOpen] = useState(false);
    // const [startDate, setStartDate] = useState(Datess);
    // const [typeid, settypeid] = useState(0);
    const [Edit, setEdit] = useState();
    const [Name, setName] = useState();
    const [Type, setType] = useState();
    const ref = useRef(null);
    const [Id, setId] = useState();
    const [Status, setStatus] = useState();
    const [Desc, setDesc] = useState('');
    const [Popup, setPopup] = useState(false);
    const [Det, setDet] = useState(false);
    const navigate = useNavigate();
    const [spinner, setspinner] = useState(false);
    const [Detail, setDetail] = useState('New');
    const [refill, setrefill] = useState([]);
    const [newAppt, setnewAppt] = useState([]);
    const [Profile, setProfile] = useState([]);
    const [cancelAppt, setcancelAppt] = useState([]);
    const [modifyAppt, setmodifyAppt] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [search, setSearch] = useState('');

    const handleKeyDown = (event) => {
        if (event?.key === 'Enter' || event === 'clear') {
            setSearch(inputValue);
            if (inputValue === '' || event === 'clear') {
                setSearch('');
                setnewC([newC[1], 0]);
                setmodifyC([modifyC[1], 0]);
                setcancelC([cancelC[1], 0]);
                setrefillC([refillC[1], 0]);
                setprofileC([profileC[1], 0]);
                return;
            }
            const data = newAppt;
            let count = data.map((row, rindex) =>
                ((inputValue !== '' && ([row?.pat_name, (row?.patient_fname + ' ' + row?.patient_lname), row?.chart_number, row?.patient_chart_number, row?.phy_name, row?.action_description?.doctor_name, row?.appt_reason, row?.action_description?.appointment_location, row?.action_description?.appointment_purpose, row?.action_description?.appt_reason, row?.action_description?.medication_text, (row?.action_description?.physician_fname + ' ' + row?.action_description?.physician_lname), row?.action_description?.name, row?.action_description?.location_name, row?.action_description?.reason_for_cancelation].some(element => element?.toString().toLowerCase().includes(inputValue.toLowerCase()))))));
            let countFiltered = count.reduce((acc, val) => val ? acc + 1 : acc, 0);
            setnewC([countFiltered, newC[0]]);
            count = modifyAppt.map((row, rindex) =>
                ((inputValue !== '' && ([row?.pat_name, (row?.patient_fname + ' ' + row?.patient_lname), row?.chart_number, row?.patient_chart_number, row?.phy_name, row?.action_description?.doctor_name, row?.appt_reason, row?.action_description?.appointment_location, row?.action_description?.appointment_purpose, row?.action_description?.appt_reason, row?.action_description?.medication_text, (row?.action_description?.physician_fname + ' ' + row?.action_description?.physician_lname), row?.action_description?.name, row?.action_description?.location_name, row?.action_description?.reason_for_cancelation].some(element => element?.toString().toLowerCase().includes(inputValue.toLowerCase()))))));

            countFiltered = count.reduce((acc, val) => val ? acc + 1 : acc, 0);
            setmodifyC([countFiltered, modifyC[0]]);
            count = cancelAppt.map((row, rindex) =>
                ((inputValue !== '' && ([row?.pat_name, (row?.patient_fname + ' ' + row?.patient_lname), row?.chart_number, row?.patient_chart_number, row?.phy_name, row?.action_description?.doctor_name, row?.appt_reason, row?.action_description?.appointment_location, row?.action_description?.appointment_purpose, row?.action_description?.appt_reason, row?.action_description?.medication_text, (row?.action_description?.physician_fname + ' ' + row?.action_description?.physician_lname), row?.action_description?.name, row?.action_description?.location_name, row?.action_description?.reason_for_cancelation].some(element => element?.toString().toLowerCase().includes(inputValue.toLowerCase()))))));

            countFiltered = count.reduce((acc, val) => val ? acc + 1 : acc, 0);
            setcancelC([countFiltered, cancelC[0]]);
            count = refill.map((row, rindex) =>
                ((inputValue !== '' && ([row?.pat_name, (row?.patient_fname + ' ' + row?.patient_lname), row?.chart_number, row?.patient_chart_number, row?.phy_name, row?.action_description?.doctor_name, row?.appt_reason, row?.action_description?.appointment_location, row?.action_description?.appointment_purpose, row?.action_description?.appt_reason, row?.action_description?.medication_text, (row?.action_description?.physician_fname + ' ' + row?.action_description?.physician_lname), row?.action_description?.name, row?.action_description?.location_name, row?.action_description?.reason_for_cancelation].some(element => element?.toString().toLowerCase().includes(inputValue.toLowerCase()))))));

            countFiltered = count.reduce((acc, val) => val ? acc + 1 : acc, 0);
            setrefillC([countFiltered, refillC[0]]);
            count = Profile.map((row, rindex) =>
                ((inputValue !== '' && ([row?.pat_name, (row?.patient_fname + ' ' + row?.patient_lname), row?.chart_number, row?.patient_chart_number, row?.phy_name, row?.action_description?.doctor_name, row?.appt_reason, row?.action_description?.appointment_location, row?.action_description?.appointment_purpose, row?.action_description?.appt_reason, row?.action_description?.medication_text, (row?.action_description?.physician_fname + ' ' + row?.action_description?.physician_lname), row?.action_description?.name, row?.action_description?.location_name, row?.action_description?.reason_for_cancelation].some(element => element?.toString().toLowerCase().includes(inputValue.toLowerCase()))))));

            countFiltered = count.reduce((acc, val) => val ? acc + 1 : acc, 0);
            setprofileC([countFiltered, profileC[0]]);
        }
    };

    const update = async (id, status, comment, type) => {
        await changeRequest({
            id,
            status,
            comment
        });
        setspinner(true);
        const mod = await modification({
            id: userInfo.email,
            start: 0,
            length: 10,
            type_id: type
        });
        if (mod?.data?.message === 'The incoming token has expired') {
            navigate('/');
            return;
        }
        if (type === 1) {
            setnewAppt((mod.message.data.filter((data) => data.type_id === 1)));
            setnewC([mod.message.count[0].count, 0]);
        }
        if (type === 2) {
            setmodifyAppt((mod.message.data.filter((data) => data.type_id === 2)));
            setmodifyC([mod.message.count[0].count, 0]);
        }
        if (type === 3) {
            setcancelAppt((mod.message.data.filter((data) => data.type_id === 3)));
            setcancelC([mod.message.count[0].count, 0]);
        }
        if (type === 4) {
            setrefill((mod.message.data.filter((data) => data.type_id === 4)));
            setrefillC([mod.message.count[0].count, 0]);
        }
        if (type === 6) {
            setProfile((mod.message.data.filter((data) => data.type_id === 6)));
            setprofileC([mod.message.count[0].count, 0]);
        }
        setspinner(false);
    };

    const refresh = async (type) => {
        setspinner(true);
        setInputValue('');
        setSearch('');
        const mod = await modification({
            id: userInfo.email,
            start: 0,
            length: 10,
            type_id: type
        });
        if (mod?.data?.message === 'The incoming token has expired') {
            navigate('/');
            return;
        }
        if (type === 1) {
            setnewAppt((mod.message.data.filter((data) => data.type_id === 1)));
            setnewC([mod.message.count[0].count, 0]);
        }
        if (type === 2) {
            setmodifyAppt((mod.message.data.filter((data) => data.type_id === 2)));
            setmodifyC([mod.message.count[0].count, 0]);
        }
        if (type === 3) {
            setcancelAppt((mod.message.data.filter((data) => data.type_id === 3)));
            setcancelC([mod.message.count[0].count, 0]);
        }
        if (type === 4) {
            setrefill((mod.message.data.filter((data) => data.type_id === 4)));
            setrefillC([mod.message.count[0].count, 0]);
        }
        if (type === 6) {
            setProfile((mod.message.data.filter((data) => data.type_id === 6)));
            setprofileC([mod.message.count[0].count, 0]);
        }
        setspinner(false);
    };

    useEffect(() => {
        (async () => {
            setspinner(true);
            const mod = await modification({
                id: userInfo.email,
                start: 0,
                length: 10,
                type_id: 1
            });
            if (mod?.data?.message === 'The incoming token has expired') {
                navigate('/');
                return;
            }
            setnewC([mod.message.count[0].count, mod.message.count[0].count]);
            setnewAppt((mod.message.data.filter((data) => data.type_id === 1)));
            // mod = await modification({
            //     id: userInfo.email,
            //     start: 0,
            //     length: 10,
            //     type_id: 2
            // });
            // if (mod?.data?.message === 'The incoming token has expired') {
            //     navigate('/signin');
            //     return;
            // }
            // setmodifyC([mod.message.count[0].count, 0]);
            // setmodifyAppt((mod.message.data.filter((data) => data.type_id === 2)));
            // mod = await modification({
            //     id: userInfo.email,
            //     start: 0,
            //     length: 10,
            //     type_id: 3
            // });
            // if (mod?.data?.message === 'The incoming token has expired') {
            //     navigate('/signin');
            //     return;
            // }
            // setcancelC([mod.message.count[0].count, 0]);
            // setcancelAppt((mod.message.data.filter((data) => data.type_id === 3)));
            // mod = await modification({
            //     id: userInfo.email,
            //     start: 0,
            //     length: 10,
            //     type_id: 4
            // });
            // if (mod?.data?.message === 'The incoming token has expired') {
            //     navigate('/signin');
            //     return;
            // }
            // setrefillC([mod.message.count[0].count, 0]);
            // setrefill((mod.message.data.filter((data) => data.type_id === 4)));
            // mod = await modification({
            //     id: userInfo.email,
            //     start: 0,
            //     length: 10,
            //     type_id: 6
            // });
            // if (mod?.data?.message === 'The incoming token has expired') {
            //     navigate('/signin');
            //     return;
            // }
            // setprofileC([mod.message.count[0].count, 0]);
            // setProfile((mod.message.data.filter((data) => data.type_id === 6)));
            setspinner(false);
        })();
        function handleClickOutside (event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setPopup(false);
                // setIsOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // const dateFilter = async (data) => {
    //     setspinner(true);
    //     const mod = await modification({
    //         id: userInfo.email,
    //         start_date: data.start_date,
    //         stop_date: data.stop_date
    //     });
    //     if (mod?.data?.message === 'The incoming token has expired') {
    //         navigate('/signin');
    //         return;
    //     }
    //     setnewAppt((mod.message.data.filter((data) => data.type_id === 1)));
    //     setmodifyAppt((mod.message.data.filter((data) => data.type_id === 2)));
    //     setcancelAppt((mod.message.data.filter((data) => data.type_id === 3)));
    //     setrefill((mod.message.data.filter((data) => data.type_id === 4)));
    //     setProfile((mod.message.data.filter((data) => data.type_id === 6)));
    //     setspinner(false);
    // };

    const page = async (data) => {
        setspinner(true);
        const mod = await modification({
            id: userInfo.email,
            start: (data.value - 1) * 10,
            length: 10,
            type_id: data.id
        });
        if (mod?.data?.message === 'The incoming token has expired') {
            navigate('/');
            return;
        }
        if (data.id === 1) {
            setnewAppt((mod.message.data.filter((data) => data.type_id === 1)));
            setnewC([mod.message.count[0].count, 0]);
        }
        if (data.id === 2) {
            setmodifyAppt((mod.message.data.filter((data) => data.type_id === 2)));
            setmodifyC([mod.message.count[0].count, 0]);
        }
        if (data.id === 3) {
            setcancelAppt((mod.message.data.filter((data) => data.type_id === 3)));
            setcancelC([mod.message.count[0].count, 0]);
        }
        if (data.id === 4) {
            setrefill((mod.message.data.filter((data) => data.type_id === 4)));
            setrefillC([mod.message.count[0].count, 0]);
        }
        if (data.id === 6) {
            setProfile((mod.message.data.filter((data) => data.type_id === 6)));
            setprofileC([mod.message.count[0].count, 0]);
        }
        setspinner(false);
    };

    // const handleChange = (e) => {
    //     setIsOpen(!isOpen);
    //     setStartDate(e);
    //     setDatess(e);
    //     const temp = {};
    //     temp.start_date = format(e, 'yyyy/MM/dd');
    //     temp.stop_date = format(e, 'yyyy/MM/dd');
    //     dateFilter(temp);
    // };
    // const handleClick = (e) => {
    //     e.preventDefault();
    //     setIsOpen(!isOpen);
    // };

    function profiletext (i) {
        const oldData = Profile[i]?.action_description?.old_data;
        const newData = Profile[i]?.action_description?.new_data;
        const changedKeys = [];

        if (oldData && newData) {
            for (const key in oldData) {
                if (oldData[key] !== newData[key]) {
                    changedKeys.push(key);
                }
            }
        }
        return changedKeys.join(', ');
    };

    const Out = (Detail) => {
        if (Detail === 'New') { return ('translate-x-[0%]'); } else if (Detail === 'Cancel') { return ('translate-x-[100%]'); } else if (Detail === 'Modify') { return ('translate-x-[200%]'); } else if (Detail === 'Treatment') { return ('translate-x-[300%]'); } else if (Detail === 'Profile') { return ('translate-x-[400%]'); }
    };
    const NAcondtions = [
        {
            name: 'Patient',
            selector: (row) =>
                (<div className="flex items-center mr-[10px]">
                    <div className='max-[1600px]:w-[25px] max-[1600px]:h-[25px] max-[1600px]:text-[9px] w-[44px] h-[44px] font-[600] font-["Montserrat"] text-[14px] leading-[17px] text-[#FFFFFF] bg-primary rounded-full flex justify-center items-center'>
                        {row?.patient_fname?.[0] + '' + row?.patient_lname?.[0]}
                    </div>
                    <div className="ml-[18px] flex flex-col justify-between">
                        <span>{row?.patient_fname + ' ' + row?.patient_lname}</span>
                        <span className='text-[#818181] max-[1600px]:text-[10px] text-[12px]'>{row?.patient_chart_number}</span>
                    </div>
                </div>)
        },
        {
            name: 'Request date',
            selector: (row) => (<div className='flex items-center font-[500]'>{row?.created_date ? moment(row?.created_date).format('MMM. DD, YYYY') : '-'}</div>)
        },
        {
            name: 'Appt date',
            selector: (row) => (<div className='flex items-center font-[500]'>{row?.action_description?.appointment_date ? moment(row?.action_description?.appointment_date).format('MMM. DD, YYYY') + ' ' + row?.action_description?.appointment_time : '-'}</div>)
        },
        {
            name: 'Doctor',
            selector: (row) => (<div className='flex items-center'>{row?.action_description?.doctor_name}</div>)
        },
        {
            name: 'Location',
            selector: (row) => (<div className='flex items-center'>{row?.action_description.appointment_location ? row?.action_description.appointment_location : '-'}</div>)
        },
        {
            name: 'Reason',
            selector: (row) => (<div className='flex items-center'>{row?.action_description.appointment_purpose ? row?.action_description.appointment_purpose : '-'}</div>)
        },
        {
            name: 'Status',
            selector: (row, i) => <div className='flex'>
                {/* <select
                    onChange={(e) => {
                        setPopup(true);
                        setDesc(`Your appointment with Dr.${row?.action_description?.doctor_name} is confirmed for ${moment(row?.action_description?.appointment_date).format('MMM. DD, YYYY')} and ${row?.action_description?.appointment_time}.`);
                        if (e.target.value === 'CLOSED') {
                            setStatus(2);
                        } else if (e.target.value === 'OPEN') {
                            setStatus(1);
                        }
                        setId(row.action_id);
                        setType('New Appt');
                        settypeid(1);
                        setName(row?.patient_fname + ' ' + row?.patient_lname);
                    }}
                    className={`outline-none border-none rounded-[6px] w-[124px] h-[27px] px-[5px] max-[1600px]:w-[90px] max-[1600px]:h-[20px] ${row?.status === 'CLOSED' ? 'bg-[rgba(255,98,79,0.15)] text-[#FF624F]' : 'bg-[rgba(0,121,188,0.15)] text-[#016095]'}`}
                    value={row?.status}>
                </select> */}
                <div className={`${row.status === 'OPEN' ? 'bg-[rgba(0,121,188,0.15)]' : 'bg-[rgba(255,98,79,0.15)]'} rounded-[6px] w-[124px] h-[27px] px-[5px] max-[1600px]:w-[90px] max-[1600px]:h-[20px] flex justify-center items-center ${row.status === 'OPEN' ? 'text-[#016095]' : 'text-[#FF624F]'}`}>
                    <p>{row.status}</p>
                </div>
            </div>
        },
        {
            name: 'Actions',
            selector: (row, i) => (<div className='flex max-[1600px]:h-[30px] justify-center items-center'>
                {/* <img src={Chat} className='max-[1280px]:w-[25px] max-[1280px]:h-[25px] max-[1600px]:w-[30px] max-[1600px]:mr-[7px] max-[1600px]:h-[30px] w-[44px] h-[44px] mr-[15px]'/> */}
                <Link to={'history/hist'} state={row}><img src={ADetails} className='max-[1280px]:w-[25px] max-[1280px]:h-[25px] max-[1600px]:w-[30px] max-[1600px]:h-[30px] w-[44px] h-[44px]'/></Link></div>)
        }
    ];
    const condtions = [
        {
            name: 'Patient',
            selector: (row) =>
                (<div className="flex items-center mr-[10px]">
                    <div className='max-[1600px]:w-[25px] max-[1600px]:h-[25px] max-[1600px]:text-[9px] w-[44px] h-[44px] font-[600] font-["Montserrat"] text-[14px] leading-[17px] text-[#FFFFFF] bg-primary rounded-full flex justify-center items-center'>
                        {row?.patient_fname?.[0] + '' + row?.patient_lname?.[0]}
                    </div>
                    <div className="ml-[18px] flex flex-col justify-between">
                        <span>{row?.patient_fname + ' ' + row?.patient_lname}</span>
                        <span className='text-[#818181] max-[1600px]:text-[10px] text-[12px]'>{row?.patient_chart_number}</span>
                    </div>
                </div>)
        },
        {
            name: 'Request date',
            selector: (row) => (<div className='flex items-center font-[500]'>{row?.created_date ? moment(row?.created_date).format('MMM. DD, YYYY') : '-'}</div>)
        },
        {
            name: 'Appt date',
            selector: (row) => (<div className='flex items-center font-[500]'>{row?.action_description?.appt_date ? moment(row?.action_description?.appt_date).format('MMM. DD, YYYY') : '-'}</div>)
        },
        {
            name: 'Doctor',
            selector: (row) => (<div className='flex items-center'>{row?.action_description?.physician_fname + ' ' + row?.action_description?.physician_lname}</div>)
        },
        {
            name: 'Location',
            selector: (row) => (<div className='flex items-center'>{row?.action_description.location_name ? row?.action_description.location_name : '-'}</div>)
        },
        {
            name: 'Reason',
            selector: (row) => (<div className='flex items-center'>{row?.action_description.reason_for_cancelation ? row?.action_description.reason_for_cancelation : '-'}</div>)
        },
        {
            name: 'Status',
            selector: (row, i) => <div className='flex'>
                {/* <select
                    onChange={(e) => {
                        setPopup(true);
                        setDesc(`Your appointment with Dr.${row?.action_description?.physician_fname + ' ' + row?.action_description?.physician_lname} on ${moment(row?.action_description.appt_date).format('MMM. DD, YYYY') + ' and ' + moment(row?.action_description.appt_time, 'HH:mm:ss').format('hh:mm A')} has been canceled. Please call the clinic to reschedule.`);
                        if (e.target.value === 'CLOSED') {
                            setStatus(6);
                        } else if (e.target.value === 'OPEN') {
                            setStatus(5);
                        }
                        setId(row.action_id);
                        setType('Cancel Appt');
                        settypeid(3);
                        setName(row?.patient_fname + ' ' + row?.patient_lname);
                    }}
                    className={`outline-none border-none rounded-[6px] w-[124px] h-[27px] px-[5px] max-[1600px]:w-[90px] max-[1600px]:h-[20px] ${row?.status === 'CLOSED' ? 'bg-[rgba(255,98,79,0.15)] text-[#FF624F]' : 'bg-[rgba(0,121,188,0.15)] text-[#016095]'}`}
                    value={row?.status}>
                    <option className='bg-[rgba(0,121,188,0.15)] text-[#016095]' value={'OPEN'}>Open</option>
                    <option className='bg-[rgba(255,98,79,0.15)]  text-[#FF624F]' value={'CLOSED'}>Closed</option>
                </select> */}
                <div className={`${row.status === 'OPEN' ? 'bg-[rgba(0,121,188,0.15)]' : 'bg-[rgba(255,98,79,0.15)]'} rounded-[6px] w-[124px] h-[27px] px-[5px] max-[1600px]:w-[90px] max-[1600px]:h-[20px] flex justify-center items-center ${row.status === 'OPEN' ? 'text-[#016095]' : 'text-[#FF624F]'}`}>
                    <p>{row.status}</p>
                </div>
            </div>
        },
        {
            name: 'Actions',
            selector: (row, i) => (<div className='flex max-[1600px]:h-[30px] justify-center items-center'>
                {/* <img src={Chat} className='max-[1280px]:w-[25px] max-[1280px]:h-[25px] max-[1600px]:w-[30px] max-[1600px]:mr-[7px] max-[1600px]:h-[30px] w-[44px] h-[44px] mr-[15px]'/> */}
                {/* <Link to={'history/hist'} state={row}> */}
                <img src={ADetails} className='max-[1280px]:w-[25px] max-[1280px]:h-[25px] max-[1600px]:w-[30px] max-[1600px]:h-[30px] w-[44px] h-[44px]'/>
                {/* </Link> */}
            </div>)
        }
    ];
    const modifyCondtions = [
        {
            name: 'Patient',
            selector: (row) =>
                (<div className="flex items-center mr-[10px] w-auto">
                    <div className='max-[1600px]:w-[25px] max-[1600px]:h-[25px] max-[1600px]:text-[8px] w-[44px] h-[44px] font-[600] font-["Montserrat"] text-[14px] leading-[17px] text-[#FFFFFF] bg-primary rounded-full flex justify-center items-center'>
                        {row?.patient_fname?.[0] + '' + row?.patient_lname?.[0]}
                    </div>
                    <div className="max-[1600px]:ml-[5px] max-[1600px]:ml-[8px] ml-[18px] flex flex-col justify-between">
                        <span>{row?.patient_fname + ' ' + row?.patient_lname}</span>
                        <span className='text-[#818181] max-[1600px]:text-[7px] text-[12px]'>{row?.patient_chart_number}</span>
                    </div>
                </div>)
        },
        {
            name: 'Request date',
            selector: (row) => (<div className='flex items-center font-[500]'>{row?.created_date ? moment(row?.created_date).format('MMM. DD, YYYY') : '-'}</div>)
        },
        {
            name: 'Appt date',
            selector: (row) => (<div className='flex items-center font-[500] w-[100px]'>{moment(row?.action_description.appt_date).format('MMM. DD, YYYY') ? moment(row?.action_description.appt_date).format('MMM. DD, YYYY') : '-'}<br/>{moment(row?.action_description.appt_time, 'HH:mm:ss').format('hh:mm A') ? moment(row?.action_description.appt_time, 'HH:mm:ss').format('hh:mm A') : '-'}</div>)
        },
        {
            name: 'Doctor',
            selector: (row) => (<div className='flex items-center'>{row?.action_description?.physician_fname + ' ' + row?.action_description?.physician_lname}</div>)
        },
        {
            name: 'Location',
            selector: (row) => (<div className='flex items-center w-[100px]'>{row?.action_description.location_name ? row?.action_description.location_name : '-'}</div>)
        },
        {
            name: 'Reason',
            selector: (row) => (<div className='flex items-center'>{row?.action_description?.appt_reason}</div>)
        },
        // {
        //     name: 'Change From',
        //     selector: (row) => (<div className='flex items-center font-[500] w-[100px]'>{moment(row?.action_description.appt_date).format('MMM. DD, YYYY') ? moment(row?.action_description.appt_date).format('MMM. DD, YYYY') + ' ' + moment(row?.action_description.appt_time, 'HH:mm:ss').format('hh:mm A') : '-'}</div>)
        // },
        {
            name: 'Change To',
            selector: (row) => (<div className='flex items-center font-[500] w-[100px]'>{moment(row?.action_description.change_date).format('MMM. DD, YYYY') ? moment(row?.action_description.change_date).format('MMM. DD, YYYY') : '-'}<br/>{moment(row?.action_description.change_time, 'HH:mm A').format('hh:mm A') ? moment(row?.action_description.change_time, 'HH:mm A').format('hh:mm A') : '-'}</div>)
        },
        {
            name: 'Status',
            selector: (row, i) => <div className='flex'>
                {/* <select
                    onChange={(e) => {
                        setPopup(true);
                        setDesc(`Your appointment has been rescheduled with Dr.${row?.action_description?.physician_fname + ' ' + row?.action_description?.physician_lname} for ${moment(row?.action_description.appt_date).format('MMM. DD, YYYY') + ' and ' + moment(row?.action_description.appt_time, 'HH:mm:ss').format('hh:mm A')}.`);
                        if (e.target.value === 'CLOSED') {
                            setStatus(4);
                        } else if (e.target.value === 'OPEN') {
                            setStatus(3);
                        }
                        setId(row.action_id);
                        setType('Modify Appt');
                        settypeid(2);
                        setName(row?.patient_fname + ' ' + row?.patient_lname);
                    }}
                    className={`outline-none border-none rounded-[6px] w-[124px] h-[27px] px-[5px] max-[1600px]:w-[90px] max-[1600px]:h-[20px] ${row?.status === 'CLOSED' ? 'bg-[rgba(255,98,79,0.15)] text-[#FF624F]' : 'bg-[rgba(0,121,188,0.15)] text-[#016095]'}`} value={row?.status}> */}
                {/* </select> */}
                <div className={`${row.status === 'OPEN' ? 'bg-[rgba(0,121,188,0.15)]' : 'bg-[rgba(255,98,79,0.15)]'} rounded-[6px] w-[124px] h-[27px] px-[5px] max-[1600px]:w-[90px] max-[1600px]:h-[20px] flex justify-center items-center ${row.status === 'OPEN' ? 'text-[#016095]' : 'text-[#FF624F]'}`}>
                    <p>{row.status}</p>
                </div>
            </div>
        },
        {
            name: 'Actions',
            selector: (row, i) => (<div className='flex max-[1600px]:h-[30px] justify-center items-center'>
                {/* <img src={Chat} className='max-[1280px]:w-[25px] max-[1280px]:h-[25px] max-[1600px]:w-[30px] max-[1600px]:mr-[7px] max-[1600px]:h-[30px] w-[44px] h-[44px] mr-[15px]'/> */}
                {/* <Link to={'history/hist'} state={row}> */}
                <img src={ADetails} className='max-[1280px]:w-[25px] max-[1280px]:h-[25px] max-[1600px]:w-[30px] max-[1600px]:h-[30px] w-[44px] h-[44px]'/>
                {/* </Link> */}
            </div>)
        }
    ];
    const pcondtions = [
        {
            name: 'Patient',
            selector: (row) =>
                (<div className="flex items-center ">
                    <div className='max-[1600px]:w-[25px] max-[1600px]:h-[25px] max-[1600px]:text-[9px] w-[44px] h-[44px] font-[600] font-["Montserrat"] text-[14px] leading-[17px] text-[#FFFFFF] bg-primary rounded-full flex justify-center items-center'>
                        {row?.patient_fname?.[0] + '' + row?.patient_lname?.[0]}
                    </div>
                    <div className="ml-[18px] flex flex-col justify-between">
                        <span>{row?.patient_fname + ' ' + row?.patient_lname}</span>
                        <span className='text-[#818181] max-[1600px]:text-[10px] text-[12px]'>{row?.patient_chart_number}</span>
                    </div>
                </div>)
        },
        {
            name: 'Request date',
            selector: (row) => (<div className='flex items-center font-[500]'>{row?.created_date ? moment(row?.created_date).format('MMM. DD, YYYY') : '-'}</div>)
        },
        {
            name: 'Medicine',
            selector: (row) => (<div className='flex items-center font-["Montserrat"] font-[600] max-[1600px]:text-[10px] text-[14px] text-[#282828]'><img src={Pill} className={'max-[1600px]:w-[15px] mr-[10px]'}/>{row?.action_description?.name ? row?.action_description?.name : '-'}</div>)
        },
        {
            name: 'Medication text',
            selector: (row) => (<div className='flex items-center font-[500]'>{row?.action_description?.medication_text ? row?.action_description?.medication_text : '-'}</div>)
        },
        {
            name: 'Prescribed by',
            selector: (row) => (<div className='flex items-center font-[500]'>{row?.action_description?.physician_fname + ' ' + row?.action_description?.physician_lname}</div>)
        },
        {
            name: 'Status',
            selector: (row, i) => <div className='flex'>
                {/* <select
                    onChange={(e) => {
                        setPopup(true);
                        setDesc(`${row?.action_description?.name ? row?.action_description?.name : '-'} refill was forwarded to "Pharmacy name" and "address" on Date and Time. Pharmacy will inform YOU when refill is ready for pickup.`);
                        if (e.target.value === 'CLOSED') {
                            setStatus(8);
                        } else if (e.target.value === 'OPEN') {
                            setStatus(7);
                        }
                        setId(row.action_id);
                        setType('Refill Request');
                        settypeid(4);
                        setName(row?.patient_fname + ' ' + row?.patient_lname);
                    }}
                    className={`outline-none border-none rounded-[6px] w-[124px] h-[27px] px-[5px] max-[1600px]:w-[90px] max-[1600px]:h-[20px] ${row?.status === 'CLOSED' ? 'bg-[rgba(255,98,79,0.15)] text-[#FF624F]' : 'bg-[rgba(0,121,188,0.15)] text-[#016095]'}`} value={row?.status}>
                    <option className='bg-[rgba(0,121,188,0.15)] text-[#016095]' value={'OPEN'}>Open</option>
                    <option className='bg-[rgba(255,98,79,0.15)]  text-[#FF624F]' value={'CLOSED'}>Closed</option>
                </select> */}
                <div className={`${row.status === 'OPEN' ? 'bg-[rgba(0,121,188,0.15)]' : 'bg-[rgba(255,98,79,0.15)]'} rounded-[6px] w-[124px] h-[27px] px-[5px] max-[1600px]:w-[90px] max-[1600px]:h-[20px] flex justify-center items-center ${row.status === 'OPEN' ? 'text-[#016095]' : 'text-[#FF624F]'}`}>
                    <p>{row.status}</p>
                </div>
            </div>
        }
    ];
    const profileCodition = [
        {
            name: 'Patient',
            selector: (row) =>
                (<div className="flex items-center ">
                    <div className='max-[1600px]:w-[25px] max-[1600px]:h-[25px] max-[1600px]:text-[9px] w-[44px] h-[44px] font-[600] font-["Montserrat"] text-[14px] leading-[17px] text-[#FFFFFF] bg-primary rounded-full flex justify-center items-center'>
                        {row?.patient_fname?.[0] + '' + row?.patient_lname?.[0]}
                    </div>
                    <div className="ml-[18px] flex flex-col justify-between">
                        <span>{row?.patient_fname + ' ' + row?.patient_lname}</span>
                        <span className='text-[#818181] max-[1600px]:text-[10px] text-[12px]'>{row?.patient_chart_number}</span>
                    </div>
                </div>)
        },
        {
            name: 'Request date',
            selector: (row) => (<div className='flex items-center font-[500]'>{row?.created_date ? moment(row?.created_date).format('MMM. DD, YYYY') : '-'}</div>)
        },
        {
            name: 'Status',
            selector: (row, i) => <div className='flex'>
                {/* <select
                    onChange={(e) => {
                        setPopup(true);
                        setDesc(`Your request for changes to "${profiletext(i)}" has been made to your profile`);
                        if (e.target.value === 'CLOSED') {
                            setStatus(10);
                        } else if (e.target.value === 'OPEN') {
                            setStatus(9);
                        }
                        setId(row.action_id);
                        setType('Profile Update');
                        settypeid(6);
                        setName(row?.patient_fname + ' ' + row?.patient_lname);
                    }}
                    className={`outline-none border-none rounded-[6px] w-[124px] h-[27px] px-[5px] max-[1600px]:w-[90px] max-[1600px]:h-[20px] ${row?.status === 'CLOSED' ? 'bg-[rgba(255,98,79,0.15)] text-[#FF624F]' : 'bg-[rgba(0,121,188,0.15)] text-[#016095]'}`} value={row?.status}>
                    <option className='bg-[rgba(0,121,188,0.15)] text-[#016095]' value={'OPEN'}>Open</option>
                    <option className='bg-[rgba(255,98,79,0.15)]  text-[#FF624F]' value={'CLOSED'}>Closed</option>
                </select> */}
                <div className={`${row.status === 'OPEN' ? 'bg-[rgba(0,121,188,0.15)]' : 'bg-[rgba(255,98,79,0.15)]'} rounded-[6px] w-[124px] h-[27px] px-[5px] max-[1600px]:w-[90px] max-[1600px]:h-[20px] flex justify-center items-center ${row.status === 'OPEN' ? 'text-[#016095]' : 'text-[#FF624F]'}`}>
                    <p>{row.status}</p>
                </div>
            </div>
        },
        {
            name: 'Actions',
            selector: (row, i) => (<div className='flex'>
                <img src={ADetails} className='max-[1280px]:w-[25px] max-[1280px]:h-[25px] max-[1600px]:w-[30px] max-[1600px]:h-[30px] w-[44px] h-[44px]'
                    data-te-toggle="tooltip"
                    data-te-placement="bottom"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    onClick={() => {
                        setDet(true);
                        setEdit(i);
                        setDesc(`Your request for changes to <b>${profiletext(i)}</b> has been made to your profile`);
                        setId(row.action_id);
                        setType('Profile Update');
                        setName(row?.patient_fname + ' ' + row?.patient_lname);
                    }}
                    title="Details"/>
            </div>)
        }
    ];

    return (
        <div className='w-full transition-all duration-[2s] h-full overflow-hidden'>
            <div style={{ paddingBottom: '50px' }} className='max-[1600px]:mt-[30px] mx-[40px] h-full z-[0] max-[1600px]:pt-[0px] pt-[50px] pb-[200px] w-[calc(100%-80px)] overflow-hidden'>
                <div className='h-full w-full flex flex-col'>
                    <div className="mb-[30px] max-[1600px]:mb-[7px]">
                        <label className='text-[#282828] max-[1600px]:text-[18px]  font-["Montserrat"] font-[600] text-[26px] leading-[32px]'>
                        Modification requests
                        </label>
                    </div>

                    <div className="w-full h-[50px] max-[1600px]:h-[35px] flex relative mb-[15px] justify-between">
                        <div className="max-[1600px]:ml-[15px] w-[20px] h-full absolute top-0 left-0 flex items-center ml-[20px]">
                            <img
                                src={Search}
                                className="max-[1600px]:h-[15px] w-[20px] h-[20px]"
                            />
                        </div>
                        <TextField
                            style={
                                'max-[1600px]:text-[13px] h-full shadow-[0px_3px_10px_0px_rgba(186,186,186,0.25)] rounded-[8px] w-full pl-[50px] text-[#414141] outline-none'
                            }
                            placeholder={'Search'}
                            name={'filter'}
                            id={'filter'}
                            type={'text'}
                            value={inputValue}
                            onChange={(event) => setInputValue(event.target.value)}
                            onKeyDown={handleKeyDown}
                        />
                        {inputValue !== '' && <div className="max-[1600px]:mr-[15px] w-[20px] h-full absolute top-0 right-0 flex items-center mr-[20px]">
                            <CloseIcon sx={{ color: '#cdcdcd' }} fontSize="small" onClick={() => { setInputValue(''); handleKeyDown('clear'); } }/>
                        </div>}

                        {/* <div className="h-[50px] max-[1600px]:h-full w-[20%] shadow-[0px_3px_10px_0px_rgba(186,186,186,0.25)] text-[#414141] relative">
                        <DropDown
                            style={{
                                height: '100%',

                                width: '100%',
                                background: '#FFFFFF',
                                borderRadius: '0px 8px 8px 0px',
                                outline: 'none',
                                boxShadow: 'none',
                                border: 'none'
                            }}
                            placeholder={'First name'}
                        />
                    </div> */}
                    </div>

                    <div className='flex justify-between items-center'>
                        <div className='flex mt-[25px] max-[1600px]:mt-[10px] w-[1250px] max-[1600px]:w-[1000px] text-center'>
                            <div className={`inline-block w-[20%] cursor-pointer font-["Montserrat"] font-[600] max-[1600px]:text-[12px] text-[16px] max-[1600px]:pb-[10px] pb-[20px] ${Detail === 'New' ? 'text-primary' : 'text-[#818181]'} duration-[0.5s]`}
                                onClick={() => { setDetail('New'); page({ id: 1, value: 1 }); }}
                            >
                                <div className='flex justify-center items-center'>New Appointment
                                    <>{!spinner
                                        ? ` ( ${newC[0]} )`
                                        : <> ( <div className='relative w-[15px] h-[10px] flex mx-[2px]'><div className='h-full bg-[#ffffff]  absolute z-[100] w-full'>
                                            <div className='flex justify-center items-center h-full'>
                                                <img className='h-5 w-5' src={spin} alt="" />
                                            </div>
                                        </div></div> ) </>}</>
                                </div>
                            </div>
                            <div className={`inline-block w-[20%] cursor-pointer font-["Montserrat"] font-[600] max-[1600px]:text-[12px] text-[16px] max-[1600px]:pb-[10px] pb-[20px] ${Detail === 'Cancel' ? 'text-primary' : 'text-[#818181]'} duration-[0.5s]`}
                                onClick={() => { setDetail('Cancel'); page({ id: 3, value: 1 }); }}
                            >
                                <div className='flex justify-center items-center'>Cancel Appointment {!spinner
                                    ? `( ${cancelC[0]} )`
                                    : <> ( <div className='relative w-[15px] h-[11px] flex mx-[2px]'><div className='h-full bg-[#ffffff]  absolute z-[100] w-full'>
                                        <div className='flex justify-center items-center h-full'>
                                            <img className='h-5 w-5' src={spin} alt="" />
                                        </div>
                                    </div></div> ) </>}
                                </div>
                            </div>
                            <div className={`inline-block w-[20%] cursor-pointer font-["Montserrat"] font-[600] max-[1600px]:text-[12px] text-[16px] max-[1600px]:pb-[10px] pb-[20px] ${Detail === 'Modify' ? 'text-primary' : 'text-[#818181]'} duration-[0.5s]`}
                                onClick={() => { setDetail('Modify'); page({ id: 2, value: 1 }); }}
                            >
                                <div className='flex justify-center items-center'>Modify Appointment {!spinner
                                    ? `( ${modifyC[0]} )`
                                    : <> ( <div className='relative w-[15px] h-[10px] flex mx-[2px]'><div className='h-full bg-[#ffffff]  absolute z-[100] w-full'>
                                        <div className='flex justify-center items-center h-full'>
                                            <img className='h-5 w-5' src={spin} alt="" />
                                        </div>
                                    </div></div> ) </>}
                                </div>
                            </div>
                            <div className={`inline-block w-[20%] cursor-pointer font-["Montserrat"] font-[600] max-[1600px]:text-[12px] text-[16px] max-[1600px]:pb-[10px] pb-[20px] ${Detail === 'Treatment' ? 'text-primary' : 'text-[#818181]'} duration-[0.5s]`}
                                onClick={() => { setDetail('Treatment'); page({ id: 4, value: 1 }); }}
                            >
                                <div className='flex justify-center items-center'>Refill Requests {!spinner
                                    ? `( ${refillC[0]} )`
                                    : <> ( <div className='relative w-[15px] h-[10px] flex mx-[2px]'><div className='h-full bg-[#ffffff]  absolute z-[100] w-full'>
                                        <div className='flex justify-center items-center h-full'>
                                            <img className='h-5 w-5' src={spin} alt="" />
                                        </div>
                                    </div></div> ) </>}
                                </div>
                            </div>
                            <div className={`inline-block w-[20%] cursor-pointer font-["Montserrat"] font-[600] max-[1600px]:text-[12px] text-[16px] max-[1600px]:pb-[10px] pb-[20px] ${Detail === 'Profile' ? 'text-primary' : 'text-[#818181]'} duration-[0.5s]`}
                                onClick={() => { setDetail('Profile'); page({ id: 6, value: 1 }); }}
                            >
                                <div className='flex justify-center items-center'>Profile Update Requests {!spinner
                                    ? `( ${profileC[0]} )`
                                    : <> ( <div className='relative w-[15px] h-[10px] flex mx-[2px]'><div className='h-full bg-[#ffffff]  absolute z-[100] w-full'>
                                        <div className='flex justify-center items-center h-full'>
                                            <img className='h-5 w-5' src={spin} alt="" />
                                        </div>
                                    </div></div> ) </>}
                                </div>
                            </div>
                        </div>
                        <div className='max-[1600px]:mr-[10px] mr-[50px] cursor-pointer'><RefreshIcon color="primary" onClick={() => {
                            let type;
                            if (Detail === 'New') {
                                type = 1;
                            } else if (Detail === 'Cancel') {
                                type = 3;
                            } else if (Detail === 'Modify') {
                                type = 2;
                            } else if (Detail === 'Treatment') {
                                type = 4;
                            } else if (Detail === 'Profile') {
                                type = 6;
                            }
                            refresh(type);
                        }
                        }/></div>
                    </div>

                    <div className='w-[500px] max-[1600px]:w-[400px] relative h-[2px] mb-[20px]'><div className={`h-[2px] absolute ${Out(Detail)} w-[50%] bg-primary duration-[1s]`}></div></div>

                    <div className={`w-[calc(500%+200px)] h-[70%] flex flex-row ${Detail === 'New' ? 'translate-x-[0%]' : Detail === 'Cancel' ? 'translate-x-[calc(-20%)]' : Detail === 'Modify' ? 'translate-x-[calc(-40%)]' : Detail === 'Treatment' ? 'translate-x-[calc(-60%)]' : Detail === 'Profile' ? 'translate-x-[calc(-80%)]' : ''} justify-between duration-[2s]`}>
                        <div className='w-[calc(20%-40px)] mr-[40px] h-full relative flex'>
                            <Loader showSpinner={spinner}/>
                            <div
                                className={
                                    'w-full bg-[#ffffff] shadow-[0px_3px_10px_0px_rgba(186,186,186,0.25)] rounded-[10px] px-[20px] font-["Montserrat"] h-full relative flex flex-col'
                                }
                            >
                                {/* <div className='flex justify-between items-center h-[75px]' >
                                    <div className='flex bg-[#ffffff] py-[25px] font-["Montserrat"] text-[16px] font-[600] text-primary relative'
                                    // ref={ref}
                                    >
                                        <img src={lt} className="mr-[10px]" onClick={() => {
                                            const dateObj = new Date(Datess);
                                            const temp = {};
                                            dateObj.setDate(dateObj.getDate() - 1);
                                            setStartDate(new Date(dateObj));
                                            setDatess(new Date(dateObj));
                                            temp.start_date = format(dateObj, 'yyyy/MM/dd');
                                            temp.stop_date = format(dateObj, 'yyyy/MM/dd');
                                            dateFilter(temp);
                                        }}/>
                                        {format(startDate, 'MMM. dd, yyyy')}
                                        <img src={Cal} className={'ml-[10px]'} onClick={handleClick}
                                        />
                                        <img src={gt} className="ml-[10px]" onClick={() => {
                                            const dateObj = new Date(Datess);
                                            const temp = {};
                                            dateObj.setDate(dateObj.getDate() + 1);
                                            setStartDate(new Date(dateObj));
                                            setDatess(new Date(dateObj));
                                            temp.start_date = format(dateObj, 'yyyy/MM/dd');
                                            temp.stop_date = format(dateObj, 'yyyy/MM/dd');
                                            dateFilter(temp);
                                        }}/>
                                        {isOpen && (
                                            <div className='absolute top-[75px]'><DatePicker selected={startDate} onChange={handleChange} inline/></div>
                                        )}
                                    </div>
                                    {/* <div className='flex bg-[#ffffff] py-[25px] font-["Montserrat"] text-[16px] font-[600] text-primary'><img src={lt} className="mr-[10px]" />18 May, 2022 - 21 May, 2022<img src={Cal} className={'ml-[10px]'}/><img src={gt} className="ml-[10px]" /></div> */}
                                {/* <select className='border-[1px] border-[#E0E0E0] flex w-[150px] h-[50px] justify-center px-[5px] rounded-[8px] outline-none text-[#818181] text-[14px] font-[600] font-["Montserrat"]'><option>Day</option><option>Week</option></select> */}
                                {/* </div> */}
                                <div className='flex max-[1600px]:h-[calc(100%-60px)] h-[calc(100%-80px)]'>
                                    <Table allData={newAppt} values={NAcondtions} interval={true} filter={search}/>
                                </div>
                                <div className='flex justify-center items-center flex-grow text-[10px]'>
                                    <Pagination count={Math.ceil((newC[0] === '-' ? 0 : newC[0]) / 10)} size='small' onChange={(event, value) => page({ id: 1, value })}/>
                                </div>
                            </div>
                        </div>
                        <div className='w-[calc(20%-40px)] mr-[40px] h-full relative'>
                            <Loader showSpinner={spinner}/>
                            <div
                                className={
                                    'w-full bg-[#ffffff] shadow-[0px_3px_10px_0px_rgba(186,186,186,0.25)] rounded-[10px] px-[20px] font-["Montserrat"] h-full relative flex flex-col'
                                }

                            >
                                {/* <div className='flex justify-between items-center h-[75px]'>
                                    <div className='flex bg-[#ffffff] py-[25px] font-["Montserrat"] text-[16px] font-[600] text-primary relative'
                                    // ref={ref}
                                    >
                                        <img src={lt} className="mr-[10px]" onClick={() => {
                                            const dateObj = new Date(Datess);
                                            const temp = {};
                                            dateObj.setDate(dateObj.getDate() - 1);
                                            setStartDate(new Date(dateObj));
                                            setDatess(new Date(dateObj));
                                            temp.start_date = format(dateObj, 'yyyy/MM/dd');
                                            temp.stop_date = format(dateObj, 'yyyy/MM/dd');
                                            dateFilter(temp);
                                        }}/>
                                        {format(startDate, 'MMM. dd, yyyy')}
                                        <img src={Cal} className={'ml-[10px]'} onClick={handleClick}
                                        />
                                        <img src={gt} className="ml-[10px]" onClick={() => {
                                            const dateObj = new Date(Datess);
                                            const temp = {};
                                            dateObj.setDate(dateObj.getDate() + 1);
                                            setStartDate(new Date(dateObj));
                                            setDatess(new Date(dateObj));
                                            temp.start_date = format(dateObj, 'yyyy/MM/dd');
                                            temp.stop_date = format(dateObj, 'yyyy/MM/dd');
                                            dateFilter(temp);
                                        }}/>
                                        {isOpen && (
                                            <div className='absolute top-[75px]'><DatePicker selected={startDate} onChange={handleChange} inline/></div>
                                        )}
                                    </div> */}
                                {/* <select className='border-[1px] border-[#E0E0E0] flex w-[150px] h-[50px] justify-center px-[5px] rounded-[8px] outline-none text-[#818181] text-[14px] font-[600] font-["Montserrat"]'><option>Day</option><option>Week</option></select> */}
                                {/* </div> */}
                                <div className='flex max-[1600px]:h-[calc(100%-60px)] h-[calc(100%-80px)]'>
                                    <Table allData={cancelAppt} values={condtions} interval={true} filter={search}/>
                                </div>
                                <div className='flex justify-center items-center flex-grow'>
                                    <Pagination count={Math.ceil((cancelC[0] === '-' ? 0 : cancelC[0]) / 10)} size='small' onChange={(event, value) => page({ id: 3, value })}/>
                                </div>
                            </div>
                        </div>
                        <div className='w-[calc(20%-40px)] mr-[40px] h-full relative'>
                            <Loader showSpinner={spinner}/>
                            <div
                                className={
                                    'w-full bg-[#ffffff] shadow-[0px_3px_10px_0px_rgba(186,186,186,0.25)] rounded-[10px] px-[20px] font-["Montserrat"] h-full relative flex flex-col'
                                }

                            >
                                {/* <div className='flex justify-between items-center h-[75px]'>
                                    <div className='flex bg-[#ffffff] py-[25px] font-["Montserrat"] text-[16px] font-[600] text-primary relative'
                                    // ref={ref}
                                    >
                                        <img src={lt} className="mr-[10px]" onClick={() => {
                                            const dateObj = new Date(Datess);
                                            const temp = {};
                                            dateObj.setDate(dateObj.getDate() - 1);
                                            setStartDate(new Date(dateObj));
                                            setDatess(new Date(dateObj));
                                            temp.start_date = format(dateObj, 'yyyy/MM/dd');
                                            temp.stop_date = format(dateObj, 'yyyy/MM/dd');
                                            dateFilter(temp);
                                        }}/>
                                        {format(startDate, 'MMM. dd, yyyy')}
                                        <img src={Cal} className={'ml-[10px]'} onClick={handleClick}
                                        />
                                        <img src={gt} className="ml-[10px]" onClick={() => {
                                            const dateObj = new Date(Datess);
                                            const temp = {};
                                            dateObj.setDate(dateObj.getDate() + 1);
                                            setStartDate(new Date(dateObj));
                                            setDatess(new Date(dateObj));
                                            temp.start_date = format(dateObj, 'yyyy/MM/dd');
                                            temp.stop_date = format(dateObj, 'yyyy/MM/dd');
                                            dateFilter(temp);
                                        }}/>
                                        {isOpen && (
                                            <div className='absolute top-[75px]'><DatePicker selected={startDate} onChange={handleChange} inline/></div>
                                        )}
                                    </div> */}
                                {/* <select className='border-[1px] border-[#E0E0E0] flex w-[150px] h-[50px] justify-center px-[5px] rounded-[8px] outline-none text-[#818181] text-[14px] font-[600] font-["Montserrat"]'><option>Day</option><option>Week</option></select> */}
                                {/* </div> */}

                                <div className='flex max-[1600px]:h-[calc(100%-60px)] h-[calc(100%-80px)]'>
                                    <Table allData={modifyAppt} values={modifyCondtions} interval={true} filter={search} />
                                </div>
                                <div className='flex justify-center items-center flex-grow'>
                                    <Pagination count={Math.ceil((modifyC[0] === '-' ? 0 : modifyC[0]) / 10)} size='small' onChange={(event, value) => page({ id: 2, value })}/>
                                </div>
                            </div>
                        </div>
                        <div className='w-[calc(20%-40px)] mr-[40px] h-full relative'>
                            <Loader showSpinner={spinner}/>
                            <div
                                className={
                                    'w-full bg-[#ffffff] shadow-[0px_3px_10px_0px_rgba(186,186,186,0.25)] rounded-[10px] px-[20px] font-["Montserrat"] h-full relative flex flex-col'
                                }

                            >
                                {/* <div className='flex justify-between items-center h-[75px]'>
                                    <div className='flex bg-[#ffffff] py-[25px] font-["Montserrat"] text-[16px] font-[600] text-primary relative'
                                    // ref={ref}
                                    >
                                        <img src={lt} className="mr-[10px]" onClick={() => {
                                            const dateObj = new Date(Datess);
                                            const temp = {};
                                            dateObj.setDate(dateObj.getDate() - 1);
                                            setStartDate(new Date(dateObj));
                                            setDatess(new Date(dateObj));
                                            temp.start_date = format(dateObj, 'yyyy/MM/dd');
                                            temp.stop_date = format(dateObj, 'yyyy/MM/dd');
                                            dateFilter(temp);
                                        }}/>
                                        {format(startDate, 'MMM. dd, yyyy')}
                                        <img src={Cal} className={'ml-[10px]'} onClick={handleClick}
                                        />
                                        <img src={gt} className="ml-[10px]" onClick={() => {
                                            const dateObj = new Date(Datess);
                                            const temp = {};
                                            dateObj.setDate(dateObj.getDate() + 1);
                                            setStartDate(new Date(dateObj));
                                            setDatess(new Date(dateObj));
                                            temp.start_date = format(dateObj, 'yyyy/MM/dd');
                                            temp.stop_date = format(dateObj, 'yyyy/MM/dd');
                                            dateFilter(temp);
                                        }}/>
                                        {isOpen && (
                                            <div className='absolute top-[75px]'><DatePicker selected={startDate} onChange={handleChange} inline/></div>
                                        )}
                                    </div> */}
                                {/* <select className='border-[1px] border-[#E0E0E0] flex w-[150px] h-[50px] justify-center px-[5px] rounded-[8px] outline-none text-[#818181] text-[14px] font-[600] font-["Montserrat"]'><option>Day</option><option>Week</option></select> */}
                                {/* </div> */}
                                <div className='flex max-[1600px]:h-[calc(100%-60px)] h-[calc(100%-80px)]'>
                                    <Table allData={refill} values={pcondtions} interval={true} filter={search}/>
                                </div>
                                <div className='flex justify-center items-center flex-grow'>
                                    <Pagination count={Math.ceil((refillC[0] === '-' ? 0 : refillC[0]) / 10)} size='small' onChange={(event, value) => page({ id: 4, value })}/>
                                </div>
                            </div>
                        </div>
                        <div className='w-[calc(20%-40px)] mr-[40px] h-full relative'>
                            <Loader showSpinner={spinner}/>
                            <div
                                className={
                                    'w-full bg-[#ffffff] shadow-[0px_3px_10px_0px_rgba(186,186,186,0.25)] rounded-[10px] px-[20px] font-["Montserrat"] h-full relative flex flex-col'
                                }>
                                {/* <div className='flex justify-between items-center h-[75px]'>
                                    <div className='flex bg-[#ffffff] py-[25px] font-["Montserrat"] text-[16px] font-[600] text-primary relative'
                                    // ref={ref}
                                    >
                                        <img src={lt} className="mr-[10px]" onClick={() => {
                                            const dateObj = new Date(Datess);
                                            const temp = {};
                                            dateObj.setDate(dateObj.getDate() - 1);
                                            setStartDate(new Date(dateObj));
                                            setDatess(new Date(dateObj));
                                            temp.start_date = format(dateObj, 'yyyy/MM/dd');
                                            temp.stop_date = format(dateObj, 'yyyy/MM/dd');
                                            dateFilter(temp);
                                        }}/>
                                        {format(startDate, 'MMM. dd, yyyy')}
                                        <img src={Cal} className={'ml-[10px]'} onClick={handleClick}
                                        />
                                        <img src={gt} className="ml-[10px]" onClick={() => {
                                            const dateObj = new Date(Datess);
                                            const temp = {};
                                            dateObj.setDate(dateObj.getDate() + 1);
                                            setStartDate(new Date(dateObj));
                                            setDatess(new Date(dateObj));
                                            temp.start_date = format(dateObj, 'yyyy/MM/dd');
                                            temp.stop_date = format(dateObj, 'yyyy/MM/dd');
                                            dateFilter(temp);
                                        }}/>
                                        {isOpen && (
                                            <div className='absolute top-[75px]'><DatePicker selected={startDate} onChange={handleChange} inline/></div>
                                        )}
                                    </div> */}
                                {/* <select className='border-[1px] border-[#E0E0E0] flex w-[150px] h-[50px] justify-center px-[5px] rounded-[8px] outline-none text-[#818181] text-[14px] font-[600] font-["Montserrat"]'><option>Day</option><option>Week</option></select> */}
                                {/* </div> */}
                                <div className='flex max-[1600px]:h-[calc(100%-60px)] h-[calc(100%-80px)]'>
                                    <Table allData={Profile} values={profileCodition} interval={true} filter={search}/>
                                </div>
                                <div className='flex justify-center items-center flex-grow'>
                                    <Pagination count={Math.ceil((profileC[0] === '-' ? 0 : profileC[0]) / 10)} size='small' onChange={(event, value) => page({ id: 6, value })}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {Popup && <div className='transition max-[1600px]:w-[calc(100%-115px)] w-[calc(100%-143px)] h-[calc(100%-75px)] flex justify-center absolute bottom-0 right-0 bg-[rgba(0,0,0,0.2)] duration-[2s] overflow-y-auto mt-[75px] z-[101]'>
                <div className='w-[600px] h-[250px] bg-[#ffffff] mt-[5%] rounded-[8px] px-[40px] py-[20px] flex flex-col justify-between'
                // ref={ref}
                >
                    <div className='flex justify-between'>
                        <div className='text-[#414141] text-[24px] font-[500]'>{Type} - Notify {Name}</div>
                        <img src={X} onClick={() => setPopup(false)}/>
                    </div>
                    <textarea
                        className={'w-full px-[5px] pt-[20px] text-[#414141] outline-none border-[#ACACAC] border-b-[1px]'}
                        placeholder={'Message'}
                        onChange={(e) => setDesc(e.target.value)}
                        defaultValue={Desc}
                    />
                    <div className='w-[100%] flex justify-between'>
                        <Button
                            text={'Cancel'}
                            onClick={() => { setPopup(false); }}
                            style={'w-[45%] p-[10px] border-primary border-[2px] font-["Montserrat"] font-[500] text-[16px] leading-[20px] text-primary text-center rounded-[10px]'}
                        />
                        <Button
                            text={'Notify'}
                            onClick={() => { update(Id, Status, Desc, 0); setPopup(false); }}
                            style={'w-[45%] p-[10px] font-["Montserrat"] font-[500] text-[16px] leading-[20px] text-[#ffffff] text-center rounded-[6px] bg-primary'}
                        />
                    </div>
                </div>
            </div>}
            {Det && <div className='transition max-[1600px]:w-[calc(100%-115px)] max-[1600px]:h-[calc(100%-50px)] w-[calc(100%-143px)] h-[calc(100%-75px)] flex justify-center absolute bottom-0 right-0 bg-[rgba(0,0,0,0.2)] duration-[2s] overflow-y-auto mt-[75px] z-[101]'>
                <div className='w-[600px] h-[475px] bg-[#ffffff] mt-[5%] rounded-[8px] px-[40px] py-[20px] flex flex-col justify-between'>
                    <div className='flex justify-between'>
                        <div className='text-[#414141] text-[24px] font-[500]'>{Type} - {Name}</div>
                        <img src={X} onClick={() => setDet(false)}/>
                    </div>
                    <div>
                        <ul>
                            <li className={'mb-[10px]'}>
                                <div className='flex w-[100%] justify-between items-center'>
                                    <div className='w-[16%] font-[500] text-primary'>
                                    </div>
                                    <div className='w-[4%]'></div>
                                    <div className='w-[30%] font-[500] text-primary'>
                                        Present
                                    </div>
                                    <div className='w-[20%] flex justify-center'>
                                    </div>
                                    <div className='w-[30%] font-[500] text-primary'>
                                        Change To
                                    </div>
                                </div>
                            </li>
                            <li className={'mb-[3px]'}>
                                <div className='flex w-[100%] justify-between items-center'>
                                    <div className='w-[16%] font-[500] text-primary'>
                                        First Name
                                    </div>
                                    <div className='w-[4%]'></div>
                                    <div className='w-[30%]' style={{ color: (Profile[Edit]?.action_description?.old_data?.firstName !== Profile[Edit]?.action_description?.new_data?.firstName) ? 'red' : '#000000' }}>
                                        {Profile[Edit]?.action_description?.old_data?.firstName}
                                    </div>
                                    <div className='w-[20%] flex justify-center'>
                                        <img src={Arrow} className={'w-[10px]'}/>
                                    </div>
                                    <div className='w-[30%]' style={{ color: (Profile[Edit]?.action_description?.old_data?.firstName !== Profile[Edit]?.action_description?.new_data?.firstName) ? 'red' : '#000000' }}>
                                        {Profile[Edit]?.action_description?.new_data?.firstName}
                                    </div>
                                </div>
                            </li>
                            <li className={'mb-[3px]'}>
                                <div className='flex w-[100%] justify-between items-center'>
                                    <div className='w-[16%] font-[500] text-primary'>
                                        Last Name
                                    </div>
                                    <div className='w-[4%]'></div>
                                    <div className='w-[30%]' style={{ color: (Profile[Edit]?.action_description?.old_data?.lastName !== Profile[Edit]?.action_description?.new_data?.lastName) ? 'red' : '#000000' }}>
                                        {Profile[Edit]?.action_description?.old_data?.lastName}
                                    </div>
                                    <div className='w-[20%] flex justify-center'>
                                        <img src={Arrow} className={'w-[10px]'}/>
                                    </div>
                                    <div className='w-[30%]' style={{ color: (Profile[Edit]?.action_description?.old_data?.lastName !== Profile[Edit]?.action_description?.new_data?.lastName) ? 'red' : '#000000' }}>
                                        {Profile[Edit]?.action_description?.new_data?.lastName}
                                    </div>
                                </div>
                            </li>
                            <li className={'mb-[3px]'}>
                                <div className='flex w-[100%] justify-between items-center'>
                                    <div className='w-[16%] font-[500] text-primary'>
                                        Gender
                                    </div>
                                    <div className='w-[4%]'></div>
                                    <div className='w-[30%]' style={{ color: (Profile[Edit]?.action_description?.old_data?.gender !== Profile[Edit]?.action_description?.new_data?.gender) ? 'red' : '#000000' }}>
                                        {Profile[Edit]?.action_description?.old_data?.gender}
                                    </div>
                                    <div className='w-[20%] flex justify-center'>
                                        <img src={Arrow} className={'w-[10px]'}/>
                                    </div>
                                    <div className='w-[30%]' style={{ color: (Profile[Edit]?.action_description?.old_data?.gender !== Profile[Edit]?.action_description?.new_data?.gender) ? 'red' : '#000000' }}>
                                        {Profile[Edit]?.action_description?.new_data?.gender}
                                    </div>
                                </div>
                            </li>
                            <li className={'mb-[3px]'}>
                                <div className='flex w-[100%] justify-between items-center'>
                                    <div className='w-[16%] font-[500] text-primary'>
                                        City
                                    </div>
                                    <div className='w-[4%]'></div>
                                    <div className='w-[30%]' style={{ color: (Profile[Edit]?.action_description?.old_data?.city !== Profile[Edit]?.action_description?.new_data?.city) ? 'red' : '#000000' }}>
                                        {Profile[Edit]?.action_description?.old_data?.city}
                                    </div>
                                    <div className='w-[20%] flex justify-center'>
                                        <img src={Arrow} className ={'w-[10px]'}/>
                                    </div>
                                    <div className='w-[30%]' style={{ color: (Profile[Edit]?.action_description?.old_data?.city !== Profile[Edit]?.action_description?.new_data?.city) ? 'red' : '#000000' }}>
                                        {Profile[Edit]?.action_description?.new_data?.city}
                                    </div>
                                </div>
                            </li>
                            <li className={'mb-[3px]'}>
                                <div className='flex w-[100%] justify-between items-center'>
                                    <div className='w-[16%] font-[500] text-primary'>
                                        D.O.B
                                    </div>
                                    <div className='w-[4%]'></div>
                                    <div className='w-[30%]' style={{ color: (Profile[Edit]?.action_description?.old_data?.dateOfBirth !== Profile[Edit]?.action_description?.new_data?.dateOfBirth) ? 'red' : '#000000' }}>
                                        {Profile[Edit]?.action_description?.old_data?.dateOfBirth}
                                    </div>
                                    <div className='w-[20%] flex justify-center'>
                                        <img src={Arrow} className={'w-[10px]'}/>
                                    </div>
                                    <div className='w-[30%]' style={{ color: (Profile[Edit]?.action_description?.old_data?.dateOfBirth !== Profile[Edit]?.action_description?.new_data?.dateOfBirth) ? 'red' : '#000000' }}>
                                        {Profile[Edit]?.action_description?.new_data?.dateOfBirth}
                                    </div>
                                </div>
                            </li>
                            <li className={'mb-[3px]'}>
                                <div className='flex w-[100%] justify-between items-center'>
                                    <div className='w-[16%] font-[500] text-primary'>
                                        Phone
                                    </div>
                                    <div className='w-[4%]'></div>
                                    <div className='w-[30%]' style={{ color: (Profile[Edit]?.action_description?.old_data?.phoneNumber !== Profile[Edit]?.action_description?.new_data?.phoneNumber) ? 'red' : '#000000' }}>
                                        {Profile[Edit]?.action_description?.old_data?.phoneNumber}
                                    </div>
                                    <div className='w-[20%] flex justify-center'>
                                        <img src={Arrow} className={'w-[10px]'}/>
                                    </div>
                                    <div className='w-[30%]' style={{ color: (Profile[Edit]?.action_description?.old_data?.phoneNumber !== Profile[Edit]?.action_description?.new_data?.phoneNumber) ? 'red' : '#000000' }}>
                                        {Profile[Edit]?.action_description?.new_data?.phoneNumber}
                                    </div>
                                </div>
                            </li>
                            <li className={'mb-[3px]'}>
                                <div className='flex w-[100%] justify-between items-center'>
                                    <div className='w-[16%] font-[500] text-primary'>
                                        State
                                    </div>
                                    <div className='w-[4%]'></div>
                                    <div className='w-[30%]' style={{ color: (Profile[Edit]?.action_description?.old_data?.state !== Profile[Edit]?.action_description?.new_data?.state) ? 'red' : '#000000' }}>
                                        {Profile[Edit]?.action_description?.old_data?.state}
                                    </div>
                                    <div className='w-[20%] flex justify-center'>
                                        <img src={Arrow} className={'w-[10px]'}/>
                                    </div>
                                    <div className='w-[30%]' style={{ color: (Profile[Edit]?.action_description?.old_data?.state !== Profile[Edit]?.action_description?.new_data?.state) ? 'red' : '#000000' }}>
                                        {Profile[Edit]?.action_description?.new_data?.state}
                                    </div>
                                </div>
                            </li>
                            <li className={'mb-[3px]'}>
                                <div className='flex w-[100%] justify-between items-center'>
                                    <div className='w-[16%] font-[500] text-primary'>
                                        Zip
                                    </div>
                                    <div className='w-[4%]'></div>
                                    <div className='w-[30%]' style={{ color: (Profile[Edit]?.action_description?.old_data?.zip !== Profile[Edit]?.action_description?.new_data?.zip) ? 'red' : '#000000' }}>
                                        {Profile[Edit]?.action_description?.old_data?.zip}
                                    </div>
                                    <div className='w-[20%] flex justify-center'>
                                        <img src={Arrow} className={'w-[10px]'}/>
                                    </div>
                                    <div className='w-[30%]' style={{ color: (Profile[Edit]?.action_description?.old_data?.zip !== Profile[Edit]?.action_description?.new_data?.zip) ? 'red' : '#000000' }}>
                                        {Profile[Edit]?.action_description?.new_data?.zip}
                                    </div>
                                </div>
                            </li>
                            <li className={'mb-[3px]'}>
                                <div className='flex w-[100%] justify-between items-center'>
                                    <div className='w-[16%] font-[500] text-primary'>
                                        Address
                                    </div>
                                    <div className='w-[4%]'></div>
                                    <div className='w-[30%]' style={{ color: (Profile[Edit]?.action_description?.old_data?.address !== Profile[Edit]?.action_description?.new_data?.address) ? 'red' : '#000000' }}>
                                        {Profile[Edit]?.action_description?.old_data?.address}
                                    </div>
                                    <div className='w-[20%] flex justify-center'>
                                        <img src={Arrow} className={'w-[10px]'}/>
                                    </div>
                                    <div className='w-[30%]' style={{ color: (Profile[Edit]?.action_description?.old_data?.address !== Profile[Edit]?.action_description?.new_data?.address) ? 'red' : '#000000' }}>
                                        {Profile[Edit]?.action_description?.new_data?.address}
                                    </div>
                                </div>
                            </li>
                            <li className={'mb-[3px]'}>
                                <div className='flex w-[100%] justify-between items-center'>
                                    <div className='w-[16%] font-[500] text-primary'>
                                        Email
                                    </div>
                                    <div className='w-[4%]'></div>
                                    <div className='w-[30%]' style={{ color: (Profile[Edit]?.action_description?.old_data?.email !== Profile[Edit]?.action_description?.new_data?.email) ? 'red' : '#000000' }}>
                                        {Profile[Edit]?.action_description?.old_data?.email}
                                    </div>
                                    <div className='w-[20%] flex justify-center'>
                                        <img src={Arrow} className={'w-[10px]'}/>
                                    </div>
                                    <div className='w-[30%]' style={{ color: (Profile[Edit]?.action_description?.old_data?.email !== Profile[Edit]?.action_description?.new_data?.email) ? 'red' : '#000000' }}>
                                        {Profile[Edit]?.action_description?.new_data?.email}
                                    </div>
                                </div>
                            </li>
                        </ul>
                    </div>
                    <div className='w-[100%] flex justify-center mt-[20px]'>
                        <Button
                            text={'Close Request'}
                            onClick={() => {
                                setPopup(true);
                                setStatus(10);
                                setDet(false);
                            }}
                            style={'w-[50%] p-[10px] font-["Montserrat"] font-[500] text-[16px] leading-[20px] text-[#ffffff] text-center rounded-[6px] bg-primary'}
                        />
                    </div>
                </div>
            </div>}
        </div>
    );
}

export default Modification;
