/* eslint-disable no-console */
import React, { useEffect, useRef, useState } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import lt from '../../../assets/dlt.svg';
import gt from '../../../assets/dgt.svg';
import Cal from '../../../assets/calendar.svg';
// import DropDown from '../../../components/DropDown';
import TextField from '../../../components/TextField';
import Search from '../../../assets/search.svg';
import Table from '../../../components/Table';
import ADetails from '../../../assets/actionDetails.svg';
// import MobilePhone from '../../../assets/mobilephone.svg';
import Sort from '../../../assets/sort.svg';
// import AppointmentDetails from './AppointmentDetails';
// import { Link } from 'react-router-dom';
import { allappt, appointments, appttypes, sendBroadcastNotification } from '../../../api/appointment';
import moment from 'moment';
import Loader from '../../../components/Loader';
import { useDispatch, useSelector } from 'react-redux';
import {
    setDate,
    setDoc,
    setLoc,
    setType,
    setDates,
    setApptDW
} from '../../../store/userReducer';
import { format } from 'date-fns';
import Loc from '../../../assets/location.png';
import Doctor from '../../../assets/doc.png';
import DatePicker from 'react-datepicker';
import Calendar from './calendar';
import color from '../../../utils/json/typeColor.json';
// import { getPresignedUrl } from '../../../api/getPresignedUrl';
import Modal from '../../../components/Modal';
import PdfModal from '../../../components/PdfModal';

function Appointment () {
    const [AllAppt, setAllAppt] = useState();
    const [Classs, setClass] = useState();
    const [Filter, setFilter] = useState();
    const [Photo, setPhoto] = useState();
    const [selectedApptClass, setSelectedApptClass] = useState(0);
    const [selectedApptType, setSelectedApptType] = useState(0);
    const ref = useRef(null);
    const dispatch = useDispatch();
    const { userInfo } = useSelector((state) => state.userInfo);
    const [spinner, setSpinner] = useState(true);
    // const [Det, setDet] = useState(false);
    const [Location, setLocation] = useState([]);
    const [Types, setTypes] = useState([]);
    const [Physician, setPhysician] = useState([]);
    const [Appointments, setAppointments] = useState([]);
    // const [Details, setDetails] = useState({});
    const [SortOrder, setSortOrder] = useState(true);
    const [Data, setData] = useState({
        location_id: userInfo.loc,
        physician_id: userInfo.doc,
        appt_reason: userInfo.type,
        start_date: userInfo.date,
        stop_date: userInfo.date
    });
    // const [SelClass, setSelClass] = useState();
    const [Datess, setDatess] = useState(new Date(userInfo.dates));
    const [selReset, setSelReset] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const [startDate, setStartDate] = useState(Datess);
    const [cfilt, setcfilt] = useState();
    const [inputValue, setInputValue] = useState('');
    const [search, setSearch] = useState('');
    const [pdfModal, setPdfModal] = useState(false);
    const [modal, setModal] = useState(false);
    const [selectedSummary, setSelectedSummary] = useState({
        chart_number: null,
        summary_filename: null
    });
    const [selectedAppt, setSelectedAppt] = useState({});
    const [selectAllCheckbox, setSelectAllCheckbox] = useState(false);
    const [broadcastMessage, setBroadcastMessage] = useState('');

    // search handler
    const handleKeyDown = (event) => {
        if (event?.key === 'Enter' || event === 'clear') {
            setSearch(inputValue);
            if (inputValue === '' || event === 'clear') {
                setSearch('');
            }
        }
    };

    const toggleModal = (state) => {
        console.log('in parent : ', state);
        console.log('selected Pat: ', selectedAppt);
        setModal(state);
    };

    const handleCreateBroadcastMessage = () => {
        console.log('onClick called');
        setModal(true);
    };

    const addApptChart = (obj) => {
        if (!selectedAppt[obj.chart_number]) {
            console.log('checkingAll: ', Object.keys(selectedAppt).length === AllAppt.length, Object.keys(selectedAppt).length, AllAppt.length);
            if (Object.keys(selectedAppt).length === AllAppt.length) {
                setSelectAllCheckbox(true);
            }
            setSelectedAppt(prev => {
                return { ...prev, [obj.chart_number]: obj };
            });
        }
    };

    const removeApptChart = (chart) => {
        const newState = { ...selectedAppt };
        delete newState[chart];
        setSelectedAppt(newState);
    };

    const toggleApptSelect = (obj) => {
        if (!selectedAppt[obj.chart_number]) {
            addApptChart(obj);
        } else {
            if (selectAllCheckbox) {
                setSelectAllCheckbox(false);
            }
            removeApptChart(obj.chart_number);
        }
    };

    const handleSelectAll = () => {
        setSelectAllCheckbox(!selectAllCheckbox);
        if (!selectAllCheckbox) {
            AllAppt.forEach(appt => {
                addApptChart(appt);
            });
        } else {
            resetSelectAll();
        }
    };

    const resetSelectAll = () => {
        setSelectAllCheckbox(false);
        setSelectedAppt({});
    };

    // date wise filter
    async function dateFilter (data) {
        setSpinner(true);
        const test = await allappt(
            JSON.stringify({
                start_date: data.start_date,
                stop_date: data.stop_date
            }), practice
        );
        setFilter(test.data);
        if (test.data.length === 0) {
            setPhysician([
                {
                    physician_id: 0,
                    physician_name: 'No Data'
                }
            ]);
            setLocation([
                {
                    dw_location_id: 0,
                    location_name: 'No Data'
                }
            ]);
            setAppointments([]);
            setAllAppt([]);
            setSpinner(false);
        } else {
            setLocation(test.data);
            setPhysician(test.data[0].physicians.sort());
            const phyloc = Data;
            phyloc.physician_id = test.data[0].physicians[0].physician_id;
            phyloc.location_id = test.data[0].dw_location_id;
            phyloc.start_date = data.start_date;
            phyloc.stop_date = data.stop_date;
            setData(phyloc);
            dispatch(setDoc(test.data[0].dw_location_id));
            const appointment = await appointments(JSON.stringify(phyloc), practice);
            setAppointments(
                appointment.all_appt.sort(function (a, b) {
                    const date1 = new Date(a.appt_date + 'T' + a.appt_time + '.000Z');
                    const date2 = new Date(b.appt_date + 'T' + b.appt_time + '.000Z');
                    return date1 - date2;
                })
            );
            setAllAppt(
                appointment.all_appt.sort(function (a, b) {
                    const date1 = new Date(a.appt_date + 'T' + a.appt_time + '.000Z');
                    const date2 = new Date(b.appt_date + 'T' + b.appt_time + '.000Z');
                    return date1 - date2;
                })
            );
            const type = await appttypes(JSON.stringify(phyloc), practice);
            setPhoto(JSON.parse(appointment.phy_base64));
            const filt = [];
            type.data.map((data) => filt.push(...data?.types));
            filt.sort((a, b) =>
                a.appointmenttypename.localeCompare(b.appointmenttypename)
            );
            setcfilt(filt);
            type.data.splice(0, 0, {
                appointmenttypeclassid: 0,
                name: 'All Appt Classes',
                types: filt
            });
            filt.splice(0, 0, {
                appointmenttypeid: 0,
                appointmenttypename: 'All Appt Types'
            });
            setTypes(filt);
            // setSelClass([
            //     {
            //         value: type.data?.[0]?.appointmenttypeclassid,
            //         label: type.data?.[0]?.name
            //     }
            // ]);
            setClass(type.data);
            setPhoto(JSON.parse(appointment.phy_base64));
            setSpinner(false);
        }
    };

    // other filter
    const filter = async (data) => {
        setSpinner(true);
        const appointment = await appointments(JSON.stringify(data), practice);
        setAppointments(appointment.all_appt);
        setAllAppt(appointment.all_appt);
        dispatch(setLoc(data.location_id));
        dispatch(setDoc(data.physician_id));
        dispatch(setType(data.appt_reason));
        dispatch(setDate(data.start_date));
        dispatch(setDates(format(Datess, 'yyyy-MM-dd')));
        setData(data);
        setPhoto(JSON.parse(appointment.phy_base64));
        setSpinner(false);
        resetSortOrder(appointment.all_appt);
        resetSelectAll();
    };

    const practice = (localStorage.getItem('practice'));

    // useEffect
    useEffect(() => {
        (async () => {
            setSpinner(true);
            // if (practice) {
            const dateObj = new Date(Datess);
            const temp = {};
            if (userInfo.apptdw === 'day') {
                temp.start_date = format(new Date(), 'yyyy/MM/dd');
                temp.stop_date = format(new Date(), 'yyyy/MM/dd');
                setStartDate(new Date());
                setDatess(new Date());
                dateFilter(temp);
            } else if (userInfo.apptdw === 'week') {
                const givenDate = new Date(dateObj);
                const dayOfWeek = givenDate.getDay();
                const difference = dayOfWeek - 1;

                const previousMonday = new Date(givenDate);
                previousMonday.setDate(
                    givenDate.getDate() - difference
                );

                temp.start_date = format(
                    new Date(previousMonday),
                    'yyyy/MM/dd'
                );
                setStartDate(new Date(previousMonday));
                setDatess(new Date(previousMonday));
                temp.stop_date = format(
                    new Date(
                        previousMonday.setDate(previousMonday.getDate() + 4)
                    ),
                    'yyyy/MM/dd'
                );
                dateFilter(temp);
            }
        })();
        function handleClickOutside (event) {
            if (ref.current && !ref.current.contains(event.target)) {
                setIsOpen(false);
            }
        }
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [ref]);

    const handleBroadCastMessage = async () => {
        toggleModal(!modal);
        const filteredData = [];
        for (const key in selectedAppt) {
            if (selectedAppt[key].is_app_installed) {
                filteredData.push(selectedAppt[key]);
            }
        }
        // const filteredData = selectedAppt.filter(appt => appt.is_app_installed);
        const data = {
            practice,
            message: broadcastMessage,
            to: filteredData
        };
        await sendBroadcastNotification(data);
        // console.log('filtered data is:', filteredData);
    };

    const handleChange = (e) => {
        setIsOpen(!isOpen);
        setStartDate(e);
        setDatess(e);
        const temp = {};
        if (userInfo.apptdw === 'day') {
            temp.start_date = format(e, 'yyyy/MM/dd');
            temp.stop_date = format(e, 'yyyy/MM/dd');
            dateFilter(temp);
        } else if (userInfo.apptdw === 'week') {
            const givenDate = new Date(e);
            const dayOfWeek = givenDate.getDay();
            const difference = dayOfWeek - 1;

            const previousMonday = new Date(givenDate);
            previousMonday.setDate(givenDate.getDate() - difference);

            temp.start_date = format(previousMonday, 'yyyy/MM/dd');
            setDatess(new Date(previousMonday));
            temp.stop_date = format(
                previousMonday.setDate(previousMonday.getDate() + 4),
                'yyyy/MM/dd'
            );
            dateFilter(temp);
        }
    };

    const handleClick = (e) => {
        e.preventDefault();
        setIsOpen(!isOpen);
    };

    const resetSortOrder = (appt) => {
        // setAppointments(
        //     appt.sort(function (a, b) {
        //         const date1 = new Date(
        //             a.appt_date + 'T' + a.appt_time + '.000Z'
        //         );
        //         const date2 = new Date(
        //             b.appt_date + 'T' + b.appt_time + '.000Z'
        //         );
        //         return date1 - date2;
        //     })
        // );
        setAllAppt(
            appt.sort(function (a, b) {
                const date1 = new Date(
                    a.appt_date + 'T' + a.appt_time + '.000Z'
                );
                const date2 = new Date(
                    b.appt_date + 'T' + b.appt_time + '.000Z'
                );
                return date1 - date2;
            })
        );
        setSortOrder(false);
    };

    const apptClassChangeHandler = (val) => {
        if (val) {
            const index = Classs?.findIndex(
                (data) => data?.appointmenttypeclassid === parseInt(val)
            );
            const ty = Classs[index]?.types;
            setSelectedApptClass(ty[0].appointmenttypeclassid);
            if (ty[0]?.appointmenttypename !== 'All Appt Types') {
                ty.splice(0, 0, {
                    appointmenttypeid: 0,
                    appointmenttypename: 'All Appt Types'
                });
            }
            if (Classs[index].name !== 'All Appt Classes') {
                const filt = Appointments.filter(function (data) {
                    return ty.find(function (item) {
                        return (
                            item.appointmenttypename === data.appt_reason
                        );
                    });
                });
                setTypes(ty);
                setAllAppt(filt);
            }
            if (Classs[index].name === 'All Appt Classes') {
                setTypes(cfilt);
                setAllAppt(Appointments);
            }
            resetSelectAll();
        }
    };

    const apptTypeChangeHandler = (val) => {
        const temp = Data;
        if (val) {
            const index = Types.findIndex(
                (data) => data?.appointmenttypeid === parseInt(val)
            );
            const apptTypeObj = Types[index];
            temp.appt_reason =
                parseInt(val) === 0
                    ? '0'
                    : apptTypeObj.appointmenttypename;
            setSelectedApptType(apptTypeObj.appointmenttypeid);
            if (apptTypeObj.appointmenttypename === 'All Appt Types') {
                const filt = Appointments.filter(function (data) {
                    return Types.find(function (item) {
                        return (
                            item.appointmenttypename === data.appt_reason
                        );
                    });
                });
                setAllAppt(filt);
            } else {
                const filt = Appointments.filter(function (data) {
                    return data.appt_reason === apptTypeObj.appointmenttypename;
                });
                setAllAppt(filt);
            }
            resetSelectAll();
        }
    };

    const condtions = [
        {
            name:
                <div className='px-[2px] py-[1px] rounded-full font-[500]'>
                    <input
                        type="checkbox"
                        id="topping"
                        name="topping"
                        value="Paneer"
                        checked={selectAllCheckbox}
                        onChange={() => handleSelectAll()}
                    />
                </div>,
            selector: (row) => (<div className='px-[2px] py-[1px] rounded-full font-[500]'>
                <input
                    type="checkbox"
                    id="topping"
                    name="topping"
                    value="Paneer"
                    checked={!!selectedAppt[row.chart_number]}
                    onChange={() => toggleApptSelect(row)}
                />
            </div>)
        },
        {
            name: (
                <div className="flex">
                    Appointment Time
                    <img
                        src={Sort}
                        className={'ml-[5px]'}
                        onClick={() => {
                            // setAppointments(
                            //     AllAppt.sort(function (a, b) {
                            //         const date1 = new Date(
                            //             a.appt_date + 'T' + a.appt_time + '.000Z'
                            //         );
                            //         const date2 = new Date(
                            //             b.appt_date + 'T' + b.appt_time + '.000Z'
                            //         );
                            //         return SortOrder ? date1 - date2 : date2 - date1;
                            //     })
                            // );
                            setAllAppt(
                                AllAppt.sort(function (a, b) {
                                    const date1 = new Date(
                                        a.appt_date + 'T' + a.appt_time + '.000Z'
                                    );
                                    const date2 = new Date(
                                        b.appt_date + 'T' + b.appt_time + '.000Z'
                                    );
                                    return SortOrder ? date1 - date2 : date2 - date1;
                                })
                            );
                            setSortOrder(!SortOrder);
                        }}
                    />
                </div>
            ),
            selector: (row) => (
                <div className="flex">
                    {row.appt_date
                        ? (
                            <>
                                {/* <img src={Edit} className="max-[1600px]:w-[15px] mr-[12px]" /> */}
                                <span className="font-[600]">
                                    {moment(row.appt_date + 'T' + row.appt_time).format('hh:mm A')}
                                </span>
                            </>
                        )
                        : (
                            'No appointments scheduled'
                        )}
                </div>
            )
        },
        {
            name: 'Patient',
            selector: (row) => (
                // <Link
                //     to="chart"
                //     state={{ name: row.pat_name, chart_id: row.chart_number }}
                // >
                <div className="flex items-center">
                    <div className='max-[1600px]:w-[25px] max-[1600px]:h-[25px] max-[1600px]:text-[9px] w-[44px] h-[44px] font-[600] font-["Montserrat"] text-[14px] leading-[17px] text-[#FFFFFF] bg-primary rounded-full flex justify-center items-center'>
                        {row.pat_name.split(' ').map((name) => name[0])}
                    </div>
                    <div className="ml-[18px] flex flex-col justify-between">
                        <span>{row.pat_name}</span>
                        <span className="text-[#818181] max-[1600px]:text-[10px] text-[12px]">
                            {row.chart_number}
                        </span>
                    </div>
                </div>
                // </Link>
            )
        },
        {
            name: 'Doctor',
            selector: (row) => (
                <div className="flex items-center">
                    {Photo && (
                        <img
                            src={`data:image/jpeg;base64,${Photo &&
                                Photo?.[
                                    Photo?.findIndex(
                                        (data) => data?.physician_name === row?.phy_name
                                    )
                                ]?.photo_base64
                            }`}
                            className={
                                'max-[1600px]:w-[25px] max-[1600px]:h-[25px] w-[35px] h-[35px] mr-[10px] rounded-[8px]'
                            }
                        />
                    )}
                    {row.phy_name}
                </div>
            )
        },
        // {
        //     name: 'Appointment Class',
        //     selector: (row) => (<div className='px-[8px] py-[1px] rounded-full font-[500]' style={color[color?.findIndex((data) => row.appt_reason.toLowerCase().includes(data.name.toLowerCase()))]?.color ? color[color?.findIndex((data) => row.appt_reason.toLowerCase().includes(data.name.toLowerCase()))]?.color === '#ffffff' ? { borderWidth: '1px', borderColor: 'rgb(211,211,211)' } : { backgroundColor: color[color?.findIndex((data) => row.appt_reason.toLowerCase().includes(data.name.toLowerCase()))]?.color } : { borderWidth: '1px', borderColor: '#0079BC' }}>{console.log('row is: ', row)}</div>)
        // },
        {
            name: 'Appointment type',
            selector: (row) => (<div className='px-[8px] py-[1px] rounded-full font-[500]' style={color[color?.findIndex((data) => row.appt_reason?.toLowerCase().includes(data.name.toLowerCase()))]?.color ? color[color?.findIndex((data) => row.appt_reason?.toLowerCase().includes(data.name.toLowerCase()))]?.color === '#ffffff' ? { borderWidth: '1px', borderColor: 'rgb(211,211,211)' } : { backgroundColor: color[color?.findIndex((data) => row.appt_reason?.toLowerCase().includes(data.name.toLowerCase()))]?.color } : { borderWidth: '1px', borderColor: '#0079BC' }}>{row.appt_reason}</div>)
        },
        {
            name: 'Current Diagnosis',
            selector: (row) => (<div className='px-[8px] py-[1px] rounded-full font-[500] truncate'>{row.primary_diagnosis?.length ? row.primary_diagnosis?.[row?.primary_diagnosis?.length - 1] : '-'}</div>)
        },
        {
            name: 'Visit summary',
            selector: (row) => (
                <div className="flex">
                    {/* <img
                        src={Chat}
                        className="max-[1280px]:w-[25px] max-[1280px]:h-[25px] max-[1600px]:w-[30px] max-[1600px]:h-[30px] w-[44px] h-[44px] mr-[15px]"
                        data-te-toggle="tooltip"
                        data-te-placement="bottom"
                        data-te-ripple-init
                        data-te-ripple-color="light"
                        title="Chat"
                    /> */}
                    {
                        row.summary_filename
                            ? (<img
                                src={ADetails}
                                className="max-[1280px]:w-[25px] max-[1280px]:h-[25px] max-[1600px]:w-[30px] max-[1600px]:h-[30px] w-[44px] h-[44px]"
                                data-te-toggle="tooltip"
                                data-te-placement="bottom"
                                data-te-ripple-init
                                data-te-ripple-color="light"
                                title="Check"
                                onClick={async () => {
                                    // setDet(true);
                                    // setDetails({
                                    //     name: row.pat_name,
                                    //     chart_id: row.chart_number,
                                    //     physician: row.phy_name,
                                    //     date: moment(row.appt_date + 'T' + row.appt_time),
                                    //     purpose: row.appt_reason,
                                    //     diagnosis: row.primary_diagnosis?.length ? row.primary_diagnosis : '-',
                                    //     reason: row.encountertype,
                                    //     discussion: row.discussiontxt,
                                    //     source_id: row.source_appt_id
                                    // });
                                    setSelectedSummary({
                                        chart_number: row.chart_number,
                                        summary_filename: row.summary_filename
                                    });
                                    setPdfModal(true);
                                }}
                            />)
                            : (<p>-</p>)
                    }
                </div>
            )
        },
        {
            name: 'App installed',
            selector: (row) => (
                <div className="flex">
                    {/* <img
                    src={Chat}
                    className="max-[1280px]:w-[25px] max-[1280px]:h-[25px] max-[1600px]:w-[30px] max-[1600px]:h-[30px] w-[44px] h-[44px] mr-[15px]"
                    data-te-toggle="tooltip"
                    data-te-placement="bottom"
                    data-te-ripple-init
                    data-te-ripple-color="light"
                    title="Chat"
                /> */}
                    {
                        row.is_app_installed
                            // ? (<img
                            //     src={MobilePhone}
                            //     className="max-[1280px]:w-[25px] max-[1280px]:h-[25px] max-[1600px]:w-[30px] max-[1600px]:h-[30px] w-[44px] h-[44px]"
                            //     data-te-toggle="tooltip"
                            //     data-te-placement="bottom"
                            //     data-te-ripple-init
                            //     data-te-ripple-color="light"
                            //     title="Check"
                            //     // onClick={async () => {
                            //     // setDet(true);
                            //     // setDetails({
                            //     //     name: row.pat_name,
                            //     //     chart_id: row.chart_number,
                            //     //     physician: row.phy_name,
                            //     //     date: moment(row.appt_date + 'T' + row.appt_time),
                            //     //     purpose: row.appt_reason,
                            //     //     diagnosis: row.primary_diagnosis?.length ? row.primary_diagnosis : '-',
                            //     //     reason: row.encountertype,
                            //     //     discussion: row.discussiontxt,
                            //     //     source_id: row.source_appt_id
                            //     // });
                            //     // setSelectedSummary({
                            //     //     chart_number: row.chart_number,
                            //     //     summary_filename: row.summary_filename
                            //     // });
                            //     // setPdfModal(true);
                            //     // }}
                            // />)
                            ? <div className='px-[8px] py-[1px] rounded-full font-[500]' style={{ backgroundColor: 'rgb(157,255,150)', borderWidth: '1px', borderColor: 'rgb(157,255,150)' }}>installed</div>
                            : <div className='px-[8px] py-[1px] rounded-full font-[500]' style={{ borderWidth: '1px', borderColor: 'rgb(211,211,211)' }}>not installed</div>

                    }
                </div>
            )
        }
    ];

    return (
        <div className="relative h-full transition-all duration-[2s]">
            <div
                style={{ paddingTop: '20px' }}
                className="mx-[40px] z-[0] h-full pt-[70px] pb-[20px]">
                <div className="mb-[30px] max-[1600px]:mb-[7px]">
                    <label className='text-[#282828] max-[1600px]:text-[18px] font-["Montserrat"] font-[600] text-[26px] leading-[32px]'>
                        Appointments
                    </label>
                </div>

                <div className="w-full h-[50px] max-[1600px]:h-[35px] flex relative mb-[15px] justify-between">
                    <div className="max-[1600px]:ml-[15px] w-[20px] h-full absolute top-0 left-0 flex items-center ml-[20px]">
                        <img
                            src={Search}
                            className="max-[1600px]:h-[15px] w-[20px] h-[20px]"
                        />
                    </div>
                    <TextField
                        style={
                            'max-[1600px]:text-[13px] h-full shadow-[0px_3px_10px_0px_rgba(186,186,186,0.25)] rounded-[8px] w-full pl-[50px] text-[#414141] outline-none'
                        }
                        placeholder={'Search'}
                        name={'filter'}
                        id={'filter'}
                        type={'text'}
                        value={inputValue}
                        onChange={(event) => setInputValue(event.target.value)}
                        onKeyDown={handleKeyDown}
                    />
                    {inputValue !== '' && <div className="max-[1600px]:mr-[15px] w-[20px] h-full absolute top-0 right-0 flex items-center mr-[20px]">
                        <CloseIcon sx={{ color: '#cdcdcd' }} fontSize="small" onClick={() => { setInputValue(''); handleKeyDown('clear'); }} />
                    </div>}
                    {/* <div className="h-[50px] max-[1600px]:h-full w-[20%] shadow-[0px_3px_10px_0px_rgba(186,186,186,0.25)] text-[#414141] relative">
                        <DropDown
                            style={{
                                height: '100%',

                                width: '100%',
                                background: '#FFFFFF',
                                borderRadius: '0px 8px 8px 0px',
                                outline: 'none',
                                boxShadow: 'none',
                                border: 'none'
                            }}
                            placeholder={'First name'}
                        />
                    </div> */}
                </div>
                {
                    <div
                        className={
                            'w-[100%] flex relative h-[calc(100%-150px)] max-[1600px]:h-[calc(100%-100px)]'
                        }
                    >
                        <Loader showSpinner={spinner} />
                        <div className="w-[100%]">
                            <div
                                className={
                                    'overflow-hidden w-full bg-[#ffffff] shadow-[0px_3px_10px_0px_rgba(186,186,186,0.25)] rounded-[10px] px-[20px] font-["Montserrat"] h-full relative flex flex-col'
                                }
                            >
                                <div className="flex h-[92px] max-[1600px]:h-[0px] max-[1600px]:mt-[25px] max-[1600px]:mb-[20px] justify-between items-center z-[2]">
                                    <div className="flex items-center">
                                        <div
                                            className='flex bg-[#ffffff] py-[25px] max-[1600px]:text-[12px] max-[1600px]:py-[0px] font-["Montserrat"] text-[16px] font-[600] text-primary relative'
                                            ref={ref}
                                        >
                                            <img
                                                src={lt}
                                                className="max-[1600px]:w-[5px] max-[1600px]:mr-[8px] mr-[10px]"
                                                onClick={() => {
                                                    const dateObj = new Date(Datess);
                                                    const temp = {};
                                                    if (userInfo.apptdw === 'day') {
                                                        dateObj.setDate(dateObj.getDate() - 1);
                                                        setStartDate(new Date(dateObj));
                                                        setDatess(new Date(dateObj));
                                                        temp.start_date = format(dateObj, 'yyyy/MM/dd');
                                                        temp.stop_date = format(dateObj, 'yyyy/MM/dd');
                                                        dateFilter(temp);
                                                    } else if (userInfo.apptdw === 'week') {
                                                        const givenDate = new Date(dateObj);
                                                        const dayOfWeek = givenDate.getDay();
                                                        const difference = dayOfWeek + 6;

                                                        const previousMonday = new Date(givenDate);
                                                        previousMonday.setDate(
                                                            givenDate.getDate() - difference
                                                        );

                                                        temp.start_date = format(
                                                            previousMonday,
                                                            'yyyy/MM/dd'
                                                        );
                                                        setStartDate(new Date(previousMonday));
                                                        setDatess(new Date(previousMonday));
                                                        temp.stop_date = format(
                                                            previousMonday.setDate(
                                                                previousMonday.getDate() + 4
                                                            ),
                                                            'yyyy/MM/dd'
                                                        );
                                                        dateFilter(temp);
                                                    }
                                                }}
                                            />
                                            {format(startDate, 'MMM. dd, yyyy')}
                                            <img
                                                src={Cal}
                                                className={'max-[1600px]:w-[13px] ml-[10px]'}
                                                onClick={handleClick}
                                            />
                                            <img
                                                src={gt}
                                                className="max-[1600px]:w-[5px] max-[1600px]:ml-[8px] ml-[10px]"
                                                onClick={() => {
                                                    const dateObj = new Date(Datess);
                                                    const temp = {};
                                                    if (userInfo.apptdw === 'day') {
                                                        dateObj.setDate(dateObj.getDate() + 1);
                                                        setStartDate(new Date(dateObj));
                                                        setDatess(new Date(dateObj));
                                                        temp.start_date = format(dateObj, 'yyyy/MM/dd');
                                                        temp.stop_date = format(dateObj, 'yyyy/MM/dd');
                                                        dateFilter(temp);
                                                    } else if (userInfo.apptdw === 'week') {
                                                        const givenDate = new Date(dateObj);
                                                        const dayOfWeek = givenDate.getDay();
                                                        const difference = dayOfWeek - 8;

                                                        const previousMonday = new Date(givenDate);
                                                        previousMonday.setDate(
                                                            givenDate.getDate() - difference
                                                        );

                                                        temp.start_date = format(
                                                            previousMonday,
                                                            'yyyy/MM/dd'
                                                        );
                                                        setStartDate(new Date(previousMonday));
                                                        setDatess(new Date(previousMonday));
                                                        temp.stop_date = format(
                                                            previousMonday.setDate(
                                                                previousMonday.getDate() + 4
                                                            ),
                                                            'yyyy/MM/dd'
                                                        );
                                                        dateFilter(temp);
                                                    }
                                                }}
                                            />
                                            {isOpen && (
                                                <div className="absolute top-[75px]">
                                                    <DatePicker
                                                        selected={startDate}
                                                        onChange={handleChange}
                                                        inline
                                                    />
                                                </div>
                                            )}
                                        </div>
                                        <img
                                            src={Loc}
                                            className={
                                                'w-[25px] max-[1600px]:w-[18px] max-[1600px]:ml-[10px] mx-[10px] ml-[20px]'
                                            }
                                        />
                                        <select
                                            value={Data.location_id}
                                            className='border-[1px] border-[#E0E0E0] flex w-[250px] h-[50px] max-[1600px]:w-[150px] max-[1600px]:text-[10px] max-[1600px]:rounded-[4px] max-[1600px]:font-[500] max-[1600px]:h-[36px] justify-center px-[5px] rounded-[8px] outline-none text-[#818181] text-[14px] font-[600] font-["Montserrat"]'
                                            onChange={async (e) => {
                                                setSelReset(!selReset);
                                                const loc =
                                                    Filter?.[
                                                        Filter?.findIndex(
                                                            (data) =>
                                                                data.dw_location_id.toString() === e.target.value
                                                        )
                                                    ]?.physicians;
                                                setPhysician(loc);
                                                const temp = Data;
                                                temp.location_id = e.target.value;
                                                temp.physician_id = loc[0].physician_id;
                                                temp.appt_reason = '0';
                                                setData(temp);
                                                const type = await appttypes(JSON.stringify(temp), practice);
                                                // setPhoto(JSON.parse(appointment.phy_base64));
                                                const filt = [];
                                                type.data.map((data) => filt.push(...data?.types));
                                                filt.sort((a, b) =>
                                                    a.appointmenttypename.localeCompare(b.appointmenttypename)
                                                );
                                                setcfilt(filt);
                                                type.data.splice(0, 0, {
                                                    appointmenttypeclassid: 0,
                                                    name: 'All Appt Classes',
                                                    types: filt
                                                });
                                                filt.splice(0, 0, {
                                                    appointmenttypeid: 0,
                                                    appointmenttypename: 'All Appt Types'
                                                });
                                                // setTypes(filt);
                                                // setSelClass([
                                                //     {
                                                //         value: type.data?.[0]?.appointmenttypeclassid,
                                                //         label: type.data?.[0]?.name
                                                //     }
                                                // ]);
                                                // // setSelClass([]);
                                                // setClass(type.data);
                                                setTypes(filt);
                                                // setSelClass([
                                                //     {
                                                //         value: type.data?.[0]?.appointmenttypeclassid,
                                                //         label: type.data?.[0]?.name
                                                //     }
                                                // ]);
                                                setClass(type.data);
                                                setSelectedApptClass(0);
                                                filter(temp);
                                            }}
                                        >
                                            {Location.map((data, i) => {
                                                return (
                                                    <option key={i} value={data.dw_location_id}>
                                                        {data.location_name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        <img
                                            src={Doctor}
                                            className={'max-[1600px]:w-[45px] w-[65px] ml-[7px]'}
                                        />
                                        <select
                                            className='border-[1px] border-[#E0E0E0] flex w-[150px] max-[1600px]:text-[10px] max-[1600px]:rounded-[4px] max-[1600px]:font-[500] max-[1600px]:h-[36px] h-[50px] justify-center px-[5px] rounded-[8px] outline-none text-[#818181] text-[14px] font-[600] font-["Montserrat"] mr-[5px]'
                                            value={Data.physician_id}
                                            onChange={async (e) => {
                                                setSelReset(!selReset);
                                                const temp = Data;
                                                temp.physician_id = parseInt(e.target.value);
                                                temp.appt_reason = '0';
                                                setData(temp);
                                                const type = await appttypes(JSON.stringify(temp), practice);
                                                // setPhoto(JSON.parse(appointment.phy_base64));
                                                const filt = [];
                                                type.data.map((data) => filt.push(...data?.types));
                                                filt.sort((a, b) =>
                                                    a.appointmenttypename.localeCompare(b.appointmenttypename)
                                                );
                                                setcfilt(filt);
                                                type.data.splice(0, 0, {
                                                    appointmenttypeclassid: 0,
                                                    name: 'All Appt Classes',
                                                    types: filt
                                                });
                                                filt.splice(0, 0, {
                                                    appointmenttypeid: 0,
                                                    appointmenttypename: 'All Appt Types'
                                                });
                                                // setTypes(filt);
                                                // console.log('preCheck: ', type.data);
                                                // setSelClass([
                                                //     {
                                                //         value: type.data?.[0]?.appointmenttypeclassid,
                                                //         label: type.data?.[0]?.name
                                                //     }
                                                // ]);
                                                // // setSelClass([]);
                                                // setClass(type.data);
                                                setTypes(filt);
                                                // setSelClass([
                                                //     {
                                                //         value: type.data?.[0]?.appointmenttypeclassid,
                                                //         label: type.data?.[0]?.name
                                                //     }
                                                // ]);
                                                setClass(type.data);
                                                setSelectedApptClass(0);
                                                // apptClassChangeHandler([{ value: 0, label: 'All Appt Classes' }]);
                                                // apptTypeChangeHandler(filt);
                                                filter(temp);
                                                // resetSortOrder();
                                            }}
                                        >
                                            {Physician.map((data, i) => {
                                                return (
                                                    <option key={i} value={data.physician_id}>
                                                        {data.physician_name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        {/* ------------------down-------------------- */}
                                        <select
                                            value={selectedApptClass}
                                            className='mx-[10px] ml-[10px] border-[1px] border-[#E0E0E0] flex w-[250px] h-[50px] max-[1600px]:w-[150px] max-[1600px]:text-[10px] max-[1600px]:rounded-[4px] max-[1600px]:font-[500] max-[1600px]:h-[36px] justify-center px-[5px] rounded-[8px] outline-none text-[#818181] text-[14px] font-[600] font-["Montserrat"]'
                                            onChange={(e) => {
                                                apptClassChangeHandler(e.target.value);
                                            }}
                                        >
                                            {Classs?.map((data, i) => {
                                                return (
                                                    <option key={i} value={data.appointmenttypeclassid}>
                                                        {data.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        {/* <DropDown
                                            style={{
                                                height: !window.matchMedia('(max-width: 1600px)')
                                                    ? '51px'
                                                    : '36px',
                                                width: !window.matchMedia('(max-width: 1600px)')
                                                    ? '250px'
                                                    : '185px',
                                                borderRadius: !window.matchMedia('(max-width: 1600px)')
                                                    ? '8px'
                                                    : '4px',
                                                outline: 'none',
                                                boxShadow: 'none',
                                                borderWidth: '1px',
                                                borderColor: '#E0E0E0',
                                                display: 'flex',
                                                fontWeight: !window.matchMedia('(max-width: 1600px)')
                                                    ? '600'
                                                    : '500',
                                                fontFamily: 'Montserrat',
                                                color: '#818181',
                                                fontSize: !window.matchMedia('(max-width: 1600px)')
                                                    ? '14px'
                                                    : '10px',
                                                paddingLeft: '10px',
                                                marginTop: !window.matchMedia('(max-width: 1600px)')
                                                    ? '0px'
                                                    : '1px',
                                                marginLeft: !window.matchMedia('(max-width: 1600px)')
                                                    ? '25px'
                                                    : '10px'
                                            }}
                                            options={Classs?.map((data, i) => ({
                                                value: data.appointmenttypeclassid,
                                                label: data.name
                                            }))}
                                            onChange={(e) => {
                                                apptClassChangeHandler(e);
                                            }}
                                            // clearable={true}
                                            defaultValue={SelClass}
                                            resetSelectionToggler={selReset}
                                        /> */}
                                        {/* ------------------up-------------------- */}
                                        {/* <DropDown
                                            style={{
                                                height: !window.matchMedia('(max-width: 1600px)')
                                                    ? '51px'
                                                    : '36px',
                                                width: !window.matchMedia('(max-width: 1600px)')
                                                    ? '250px'
                                                    : '170px',
                                                borderRadius: !window.matchMedia('(max-width: 1600px)')
                                                    ? '8px'
                                                    : '4px',
                                                outline: 'none',
                                                boxShadow: 'none',
                                                borderWidth: '1px',
                                                borderColor: '#E0E0E0',
                                                display: 'flex',
                                                fontWeight: !window.matchMedia('(max-width: 1600px)')
                                                    ? '600'
                                                    : '500',
                                                fontFamily: 'Montserrat',
                                                color: '#818181',
                                                fontSize: !window.matchMedia('(max-width: 1600px)')
                                                    ? '14px'
                                                    : '10px',
                                                paddingLeft: '10px',
                                                marginTop: !window.matchMedia('(max-width: 1600px)')
                                                    ? '0px'
                                                    : '1px',
                                                marginLeft: !window.matchMedia('(max-width: 1600px)')
                                                    ? '25px'
                                                    : '10px'
                                            }}
                                            options={Types?.map((data, i) => ({
                                                value: data.appointmenttypeid,
                                                label: data.appointmenttypename
                                            }))}
                                            onChange={(e) => {
                                                apptTypeChangeHandler(e);
                                            }}
                                            placeholder={'All Appt types'}
                                            defaultValue={
                                                // selType
                                                [
                                                    {
                                                        value: Types?.[0]?.appointmenttypeid,
                                                        label: Types?.[0]?.appointmenttypename
                                                    }
                                                ]
                                            }
                                        /> */}
                                        <select
                                            value={selectedApptType}
                                            className='mx-[10px] ml-[10px] border-[1px] border-[#E0E0E0] flex w-[250px] h-[50px] max-[1600px]:w-[150px] max-[1600px]:text-[10px] max-[1600px]:rounded-[4px] max-[1600px]:font-[500] max-[1600px]:h-[36px] justify-center px-[5px] rounded-[8px] outline-none text-[#818181] text-[14px] font-[600] font-["Montserrat"]'
                                            onChange={(e) => {
                                                apptTypeChangeHandler(e.target.value);
                                            }}
                                        >
                                            {Types?.map((data, i) => {
                                                return (
                                                    <option key={i} value={data.appointmenttypeid}>
                                                        {data.appointmenttypename}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                        <label className="text-[#818181] max-[1600px]:text-[10px] max-[1600px]:font-[500] text-[14px] font-[600] max-[1600px]:ml-[10px] ml-[25px]"># Appt :
                                            <span className="text-[16px] max-[1600px]:text-[10px] font-[600] text-primary">
                                                {AllAppt?.length}
                                            </span>
                                        </label>
                                    </div>
                                    <div>
                                        <button className="border-[1px] border-[#E0E0E0] hover:bg-primary text-[#818181] hover:text-white font-bold py-2 px-2 rounded max-[1600px]:font-[500] text-[12px]" disabled={!Object.keys(selectedAppt).length} onClick={() => handleCreateBroadcastMessage()}>
                                            Broadcast Notification
                                        </button>
                                    </div>
                                    <select
                                        className='border-[1px] border-[#E0E0E0] flex w-[120px] h-[50px] max-[1600px]:w-auto max-[1600px]:text-[10px] max-[1600px]:rounded-[4px] max-[1600px]:font-[500] max-[1600px]:h-[36px] justify-center px-[5px] rounded-[8px] outline-none text-[#818181] text-[14px] font-[600] font-["Montserrat"]'
                                        onChange={(e) => {
                                            dispatch(setApptDW(e.target.value));
                                            const dateObj = new Date(Datess);
                                            const temp = {};
                                            if (e.target.value === 'day') {
                                                temp.start_date = format(new Date(), 'yyyy/MM/dd');
                                                temp.stop_date = format(new Date(), 'yyyy/MM/dd');
                                                setStartDate(new Date());
                                                setDatess(new Date());
                                                dateFilter(temp);
                                            } else if (e.target.value === 'week') {
                                                const givenDate = new Date(dateObj);
                                                const dayOfWeek = givenDate.getDay();
                                                const difference = dayOfWeek - 1;

                                                const previousMonday = new Date(givenDate);
                                                previousMonday.setDate(
                                                    givenDate.getDate() - difference
                                                );

                                                temp.start_date = format(
                                                    new Date(previousMonday),
                                                    'yyyy/MM/dd'
                                                );
                                                setStartDate(new Date(previousMonday));
                                                setDatess(new Date(previousMonday));
                                                temp.stop_date = format(
                                                    new Date(
                                                        previousMonday.setDate(previousMonday.getDate() + 4)
                                                    ),
                                                    'yyyy/MM/dd'
                                                );
                                                dateFilter(temp);
                                            }
                                        }}
                                        value={userInfo.apptdw}
                                    >
                                        <option value="day">Day</option>
                                        <option value="week">Week</option>
                                    </select>
                                </div>
                                {userInfo.apptdw === 'day'
                                    ? (
                                        <div className="h-[calc(100%-110px)] max-[1600px]:h-[calc(100%-50px)] flex">
                                            <Table allData={AllAppt} values={condtions} filter={search} />
                                        </div>
                                    )
                                    : (
                                        <Calendar Appointments={AllAppt} Datess={Datess} filter={search} />
                                    )}
                            </div>
                        </div>
                    </div>
                }
            </div>

            {/* {Det && (
                <AppointmentDetails setDet={(data) => setDet(data)} Details={Details} />
            )} */}

            {pdfModal && <PdfModal isOpen={pdfModal} heading={'heading'} body={'im bodyyyy'} pdfData={selectedSummary} toggleModal={() => setPdfModal(!pdfModal)} closeOnOutsideClick={true} />}
            {modal && <Modal closeOnOutsideClick isOpen={modal} toggleModal={toggleModal} heading={'hello world'} body={'this is the body of the pdfModal which is created by abinesh'}>
                <div className='flex flex-col justify-between w-96'>
                    <div className='mb-4 flex flex-row justify-between'><h3>Broadcast Notification</h3>
                        <div className='cursor-pointer'><CloseIcon sx={{ color: '#cdcdcd' }} fontSize="small" onClick={() => toggleModal()} /></div>
                    </div>
                    <div className='flex flex-row mb-4'>
                        <div className='px-[6px] cursor-pointer mr-2 py-[1px] rounded-full font-[500] bg-green-200 text-green-800 border-green-500' onClick={() => setBroadcastMessage('Your doctor appointment today is postponed by ___ hours.')}>Delayed</div>
                        <div className='px-[6px] cursor-pointer mr-2 py-[1px] rounded-full font-[500] bg-yellow-200 text-yellow-800 border-yellow-500' onClick={() => setBroadcastMessage('Your doctor appointment has been postponed to ___. Please contact the practice for further information.')}>Postponed</div>
                    </div>
                    <div className='mb-4'>
                        <textarea className="w-full caret-black pl-2 resize-none rounded-md border-[1px] border-[#E0E0E0] h-60" value={broadcastMessage} onChange={(evt) => setBroadcastMessage(evt.target.value)} maxLength={250}></textarea>
                    </div>
                    {/* <p>{}</p> */}
                    <button className="mb-4 border-[1px] border-[#E0E0E0] bg-primary hover:bg-sky-700 text-white hover:text-white font-bold py-2 px-2 rounded max-[1600px]:font-[500] text-[12px]" onClick={() => handleBroadCastMessage()} >
                        Send
                    </button>
                </div>
            </Modal>}
        </div>
    );
}

export default Appointment;
