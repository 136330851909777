/* eslint-disable no-console */
import React, { useEffect, useState } from 'react';
import Table from '../../../components/Table';
// import ADetails from '../../../assets/actionDetails.svg';
import Back from '../../../assets/back.svg';
// import Tick from '../../../assets/tick.svg';
// import Pill from '../../../assets/pill.svg';
import Button from '../../../components/Button';
// import Details from './Details';
import { Link, useNavigate, useParams } from 'react-router-dom';
// Removing it for audit - Testing
import { getPatienInvitetHistory, info, inviteUser, inviteUserChat } from '../../../api/chart';
// import { getPatienInvitetHistory, info, inviteUserChat } from '../../../api/chart';
import Loader from '../../../components/Loader';
import moment from 'moment';
import { CircularProgress, Snackbar } from '@mui/material';
import { config } from '../../../utils/config';
import Modal from '../../../components/Modal';

function PatientChart () {
    const navigate = useNavigate();
    const [Info, setInfo] = useState();
    // const [Detail, setDetail] = useState(true);
    // const [Det, setDet] = useState(false);
    // const [Medication, setMedication] = useState([]);
    const par = useParams();
    const [PatientData, setPatientData] = useState();
    const [spinner, setSpinner] = useState(true);
    const [open, setOpen] = useState(true);
    const [transStatus, setTransStatus] = useState(false);
    const [chatInviteStatus, setChatInviteStatus] = useState(false);
    const [modal, setModal] = useState(false);
    const [selectedDepartment, setSelectedDepartment] = useState('Urgent');
    const [patPractice, setPatPractice] = useState('');
    const [invitePopup, setInvitePopup] = useState(false);

    const practice = localStorage.getItem('practice');
    console.log('patientData::', PatientData);
    useEffect(() => {
        (async () => {
            setSpinner(true);
            //         console.log('loc is: ', loc);
            const information = await info(par.id, { practice });
            if (information?.data?.message === 'The incoming token has expired') {
                navigate('/');
                return;
            }
            console.log('photo', information);
            setInfo(information.userInfo);
            setPatPractice(information.userInfo.practice.toLowerCase());
            const history = await getPatienInvitetHistory({
                firstname: information.userInfo.fname,
                lastname: information.userInfo.lname,
                dob: information.userInfo.dob,
                gender: information.userInfo.gender,
                email: information.userInfo.email_address,
                mobilephone: information.userInfo.mobile
            });
            console.log('patientHistory is: ', history);
            setPatientData(history);
            setSpinner(false);
        })();
    }, []);

    // useEffect(() => {
    //     console.log('listening location change: ', par);
    // }, [loc]);

    const condtions = [
        {
            name: 'Sent Date',
            selector: (row) => <div className='font-[600]'>{moment.utc(row.created_at).local().format('MMM. DD, yyyy')}</div>
        },
        {
            name: 'Sent Time',
            selector: (row) => <div className='font-[600]'>{moment.utc(row.created_at).local().format('hh:mm A')}</div>
        },
        {
            name: 'Invite Link',
            selector: (row) => <div onClick={() => { navigator.clipboard.writeText(`${config[config.APPNAME].WEB}/token/${row.hash}`); setOpen(true); }} className='font-[600] truncate pr-10'>{`${config[config.APPNAME].WEB}/token/${row.hash}`}</div>
        },
        {
            name: 'Status',
            // selector: (row, i) => (<Status row = {row} i={i} r={ row } value={new Date() < new Date(row.appt_date + 'T' + row.appt_time + 'Z') ? 'Upcoming' : 'Complete'} />)
            selector: (row, i) => {
                // const now = new Date();
                const now = moment.utc().local();
                const expby = moment.utc(row.created_at).local().add(30, 'd');
                const status = row.status ? 'Accepted' : now > expby ? 'Expired' : 'Active';
                return <div className={`${status === 'Accepted' ? 'bg-[rgba(4,204,0,0.15)] text-[#038600]' : status === 'Active' ? 'bg-[rgba(0,121,188,0.15)] text-[#016095]' : 'text-[#818181] bg-[#E0E0E0]'} rounded-[6px] w-[124px] h-[27px] px-[5px] max-[1600px]:w-[90px] max-[1600px]:h-[20px] flex justify-center items-center`}>
                    <p>
                        {status}
                        {/* {new Date() < new Date(row.appt_date + 'T' + row.appt_time + 'Z') ? 'Complete' : 'Upcoming'} */}
                    </p>
                </div>;
            }
        }
    ];

    const handleSendInvite = async () => {
        console.log('inviting user: ');
        setTransStatus(true);
        try {
            if (Info) {
                const prog = await inviteUser({
                    firstname: Info.fname,
                    lastname: Info.lname,
                    dob: Info.dob,
                    gender: Info.gender,
                    email: Info.email_address,
                    mobilephone: Info.mobile,
                    practice
                });
                if (prog) {
                    console.log('user invited', prog);
                    const hash = prog.split('/')[prog.split('/').length - 1];
                    const newInv = {
                        created_at: new Date(),
                        hash
                    };
                    setPatientData(prev => [newInv, ...prev]);
                } else {
                    alert('error occured, try again later');
                }
            }
        } catch (e) {
            alert('error occured, try again later');
        } finally {
            setTransStatus(false);
            setInvitePopup(false);
        }
    };

    const toggleModal = () => {
        setModal(!modal);
    };

    const handleChatInvite = async () => {
        console.log('inviting user: ');
        setChatInviteStatus(true);
        try {
            if (Info) {
                const prog = await inviteUserChat(par.id, { practice: patPractice, department: selectedDepartment });
                if (prog) {
                    alert('patient chat notified');
                } else {
                    alert('error occured, try again later');
                }
            }
        } catch (e) {
            alert('error occured, try again later');
        } finally {
            setChatInviteStatus(false);
            setSelectedDepartment('Urgent');
            setModal(!modal);
        }
    };

    return (
        <div className='relative h-full transition-all duration-[2s] overflow-hidden'>
            {spinner && <div className='absolute z-[100] h-full w-full'>
                <Loader showSpinner={spinner} />
            </div>}
            <div style={{ paddingBottom: '40px' }} className='m-[40px] z-[0] h-full'>
                <div className='flex items-center max-[1600px]:mb-[20px] mb-[30px]'>
                    <img src={Back} className='w-[20px] h-[15px]' alt='back' />
                    <Link to='/dashboard/patient'><Button
                        text={'Back'}
                        style={'font-["Montserrat"] contents font-[500] max-[1600px]:text-[13px] text-[16px] leading-[20px] text-primary text-center rounded-[10px] h-[15px] gap-[10px]'}
                    /></Link>
                </div>
                <div className="flex justify-between max-[1600px]:mb-[20px] mb-[30px]">
                    <label className='text-[#282828] max-[1600px]:text-[18px] font-["Montserrat"] font-[600] text-[26px] leading-[32px]'>
                        Patient Chart
                    </label>
                    <span className={`${chatInviteStatus ? 'text-secondary' : 'text-primary'} font-["Montserrat"] font-[600] text-[14px] leading-[17px] flex items-center h-[25px] cursor-pointer`} onClick={() => setModal(!modal)}>
                        <span className="text-[22px] pr-[5px]">{chatInviteStatus ? <CircularProgress color='inherit' size={20} /> : '+'}</span>Invite User for Chat
                    </span>
                </div>
                <div className="w-full max-[1600px]:h-[75px] h-[100px] flex items-center relative mb-[15px] font-['Montserrat'] bg-[#FFFFFF] shadow-[0px_3px_15px_0px_rgba(186,186,186,0.5)] rounded-[8px] p-[20px]">
                    <div className="flex items-center w-[20%]">
                        {Info?.photo_base64
                            ? <img src={`data:image/jpeg;base64,${Info?.photo_base64}`} className={'w-[44px] h-[44px] rounded-[8px]'} alt={''} />
                            : <div className='w-[44px] h-[44px] font-[600] text-[14px] leading-[17px] text-[#FFFFFF] bg-primary rounded-full flex justify-center items-center'>{Info?.fname[0]}{Info?.lname[0]}</div>
                        }
                        <div className="ml-[18px] flex flex-col justify-between max-[1600px]:text-[13px]">
                            <span className='text-[#818181] max-[1600px]:text-[10px] text-[12px]'>{Info?.fname} {Info?.lname}</span>
                        </div>
                    </div>
                    <div className='bg-[#E0E0E0] h-[60px] w-[2px]'></div>

                    <div className='w-[calc(80%-32px)] flex flex-row justify-around m-[20px]'>
                        <div className='w-[15%] flex flex-col h-[100%] justify-around'><label className='font-[600] max-[1600px]:text-[12px] text-[14px] leading-[17px] my-[5px] text-primary'>Gender</label><label className='max-[1600px]:text-[12px] text-[14px] font-[400] leading-[17px] my-[5px] text-[#000000]'>{Info?.gender === 'M' ? 'Male' : 'Female'}</label></div>
                        <div className='w-[25%] flex flex-col h-[100%] justify-around'><label className='font-[600] max-[1600px]:text-[12px] text-[14px] leading-[17px] my-[5px] text-primary'>Birthday</label><label className='max-[1600px]:text-[12px] text-[14px] font-[400] leading-[17px] my-[5px] text-[#000000]'>{Info?.dob}</label></div>
                        <div className='w-[25%] flex flex-col h-[100%] justify-around'><label className='font-[600] max-[1600px]:text-[12px] text-[14px] leading-[17px] my-[5px] text-primary'>Phone number</label><label className='max-[1600px]:text-[12px] text-[14px] font-[400] leading-[17px] my-[5px] text-[#000000]'>{Info?.mobile ? <>{Info.mobile}</> : '-'}</label></div>
                        <div className='w-[20%] flex flex-col h-[100%] justify-around'><label className='font-[600] max-[1600px]:text-[12px] text-[14px] leading-[17px] my-[5px] text-primary'>Email</label><label className='max-[1600px]:text-[12px] text-[14px] font-[400] leading-[17px] my-[5px] text-[#000000]'>{Info?.email_address ? Info.email_address : '-'}</label></div>
                    </div>
                </div>

                <div className="flex justify-between mb-[30px]">
                    <label className='text-[#282828] max-[1600px]:text-[18px] font-["Montserrat"] font-[600] text-[26px] leading-[32px]'>
                        Invite History
                    </label>
                    <span className={`${transStatus ? 'text-secondary' : 'text-primary'} font-["Montserrat"] font-[600] text-[14px] leading-[17px] flex items-center h-[25px] cursor-pointer`} onClick={() => setInvitePopup(!invitePopup)} placeholder='Select Department'>
                       + Invite user
                    </span>
                </div>
                {/* <div className="w-full max-[1600px]:h-[75px] h-[100px] flex relative mb-[15px] font-['Montserrat'] bg-[#FFFFFF] shadow-[0px_3px_15px_0px_rgba(186,186,186,0.5)] rounded-[8px] p-[20px]"> */}
                {/* <div className="flex items-center w-[20%]">
                        {Info?.photo_base64
                            ? <img src={`data:image/jpeg;base64,${Info?.photo_base64}`} className={'w-[44px] h-[44px] rounded-[8px]'} alt={''} />
                            : <div className='w-[44px] h-[44px] font-[600] text-[14px] leading-[17px] text-[#FFFFFF] bg-primary rounded-full flex justify-center items-center'>{Info?.fname}</div>
                        }
                        <div className="ml-[18px] flex flex-col justify-between max-[1600px]:text-[13px]">
                            <span className='text-[#818181] max-[1600px]:text-[10px] text-[12px]'>{Info?.fname} {Info?.lname}</span>
                        </div>
                    </div>
                    <div className='bg-[#E0E0E0] h-[60px] w-[2px]'></div>

                    <div className='w-[calc(80%-32px)] flex flex-row justify-around m-[20px]'>
                        <div className='w-[15%] flex flex-col h-[100%] justify-around'><label className='font-[600] max-[1600px]:text-[12px] text-[14px] leading-[17px] my-[5px] text-primary'>Gender</label><label className='max-[1600px]:text-[12px] text-[14px] font-[400] leading-[17px] my-[5px] text-[#000000]'>{Info?.gender === 'M' ? 'Male' : 'Female'}</label></div>
                        <div className='w-[25%] flex flex-col h-[100%] justify-around'><label className='font-[600] max-[1600px]:text-[12px] text-[14px] leading-[17px] my-[5px] text-primary'>Birthday</label><label className='max-[1600px]:text-[12px] text-[14px] font-[400] leading-[17px] my-[5px] text-[#000000]'>{Info?.dob}</label></div>
                        <div className='w-[25%] flex flex-col h-[100%] justify-around'><label className='font-[600] max-[1600px]:text-[12px] text-[14px] leading-[17px] my-[5px] text-primary'>Phone number</label><label className='max-[1600px]:text-[12px] text-[14px] font-[400] leading-[17px] my-[5px] text-[#000000]'>{Info?.mobile ? <>{Info.mobile}</> : '-'}</label></div>
                        <div className='w-[20%] flex flex-col h-[100%] justify-around'><label className='font-[600] max-[1600px]:text-[12px] text-[14px] leading-[17px] my-[5px] text-primary'>Email</label><label className='max-[1600px]:text-[12px] text-[14px] font-[400] leading-[17px] my-[5px] text-[#000000]'>{Info?.email_address ? Info.email_address : '-'}</label></div>
                    </div> */}
                {/* </div> */}
                {/*

                <div className='flex mt-[25px] max-[1600px]:mt-[15px] max-[1600px]:w-[300px] w-[400px] text-center'>
                     <div className={`inline-block w-[50%] font-["Montserrat"] font-[600] text-[16px] max-[1600px]:text-[13px] pb-[20px] max-[1600px]:pb-[10px] ${Detail ? 'text-primary' : 'text-[#818181]'} duration-[0.5s]`}
                        onClick={() => setDetail(true)}
                    >
                        Appointments
                    </div>
                    <div className={`inline-block w-[50%] font-["Montserrat"] font-[600] text-[16px] max-[1600px]:text-[13px] pb-[20px] max-[1600px]:pb-[10px] ${!Detail ? 'text-primary' : 'text-[#818181]'} duration-[0.5s]`}
                        onClick={() => setDetail(false)}
                    >
                        Medication
                    </div>
                </div>

                <div className='w-[400px] max-[1600px]:w-[300px] relative h-[2px] mb-[20px]'><div className={`h-[2px] absolute ${Detail ? 'translate-x-[0%]' : 'translate-x-[100%]'} w-[50%] bg-primary duration-[1s]`}></div></div> */}

                {/* <div style={{ height: '50%' }} className={`w-[calc(200%+80px)] h-[50%] flex ${Detail ? 'translate-x-0' : 'translate-x-[calc(-50%-40px)]'} justify-between duration-[2s]`}> */}
                <div className='w-[calc(50%-40px)] h-[50%] w-full'>
                    <div
                        className={
                            'w-full bg-[#ffffff] shadow-[0px_3px_10px_0px_rgba(186,186,186,0.25)] rounded-[10px] px-[20px] font-["Montserrat"] h-full relative flex flex-col'
                        }
                    >
                        <Table allData={PatientData} values={condtions} />
                    </div>
                </div>
                <Snackbar
                    anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
                    open={!open}
                    onClose={() => setOpen(false)}
                    message="I love snacks"
                    key={'bottom' + 'right'}
                />
                {/* <div className='w-[calc(50%-40px)] h-full'>
                        <div
                            className={
                                'w-full bg-[#ffffff] shadow-[0px_3px_10px_0px_rgba(186,186,186,0.25)] rounded-[10px] px-[20px] font-["Montserrat"] h-full relative flex flex-col'
                            }
                        >
                            <Table allData={Medication} values={pcondtions} />
                        </div>
                    </div> */}
                {/* </div> */}
            </div>
            {/* </div> */}
            {modal && <Modal closeOnOutsideClick isOpen={modal} toggleModal={toggleModal} heading={'hello world'} body={'this is the body of the pdfModal which is created by abinesh'}>
                <div className='flex flex-col justify-between w-96'>
                    <div className='mb-4'><h3>Select Department to Invite</h3></div>
                    <div className='mb-4'>
                        <select
                            value={selectedDepartment}
                            className='w-full border-[1px] border-[#E0E0E0] flex h-[50px] max-[1600px]:text-[10px] max-[1600px]:rounded-[4px] max-[1600px]:font-[500] max-[1600px]:h-[36px] justify-center px-[5px] rounded-[8px] outline-none text-[#818181] text-[14px] font-[600] font-["Montserrat"]'
                            onChange={(e) => {
                                console.log('target value is: ', e.target.value);
                                setSelectedDepartment(e.target.value);
                            }}
                        >
                            <option key={1} value={'Urgent'}>
                                Urgent
                            </option>
                            <option key={2} value={'Appointment'}>
                                Appointment
                            </option>
                            <option key={3} value={'Payment and Billing'}>
                                Payment and Billing
                            </option>
                            <option key={4} value={'Medications'}>
                                Medications
                            </option>
                            <option key={5} value={'Patient Care'}>
                                Patient care
                            </option>
                            <option key={6} value={'Test Results, Images'}>
                                Test Results, Images
                            </option>
                            <option key={7} value={'Other'}>
                                Other
                            </option>
                        </select>
                    </div>
                    {/* <div className='flex flex-row mb-4'>
                        <div className='px-[6px] cursor-pointer mr-2 py-[1px] rounded-full font-[500] bg-green-200 text-green-800 border-green-500' onClick={() => setBroadcastMessage('Your doctor appointment today is postponed by ___ hours.')}>Delayed</div>
                        <div className='px-[6px] cursor-pointer mr-2 py-[1px] rounded-full font-[500] bg-yellow-200 text-yellow-800 border-yellow-500' onClick={() => setBroadcastMessage('Your doctor appointment has been postponed to ___. Please contact the practice for further information.')}>Postponed</div>
                    </div>
                    <div className='mb-4'>
                        <textarea className="w-full caret-black pl-2 resize-none rounded-md border-[1px] border-[#E0E0E0] h-60" value={broadcastMessage} onChange={(evt) => setBroadcastMessage(evt.target.value)} maxLength={250}></textarea>
                    </div> */}
                    {/* <p>{}</p> */}
                    <button className="mb-4 border-[1px] border-[#E0E0E0] bg-primary hover:bg-sky-700 text-white hover:text-white font-bold py-2 px-2 rounded max-[1600px]:font-[500] text-[12px]" onClick={() => handleChatInvite()} disabled={chatInviteStatus} >
                        <span className="text-[22px] pr-[5px]">{chatInviteStatus ? <CircularProgress color='inherit' size={20} /> : '+'}</span> Invite
                    </button>
                </div>
            </Modal>}
            {invitePopup && <Modal closeOnOutsideClick isOpen={invitePopup} toggleModal={setInvitePopup}>
                <div className='flex flex-col gap-4'>
                    <h2>Are you sure you want to invite {Info?.fname} {Info?.lname} ?</h2>
                    <button className="mb-4 border-[1px] border-[#E0E0E0] bg-primary hover:bg-sky-700 text-white hover:text-white font-bold py-2 px-2 rounded max-[1600px]:font-[500] text-[12px]" onClick={() => handleSendInvite()} disabled={transStatus} >
                        <span className="text-[22px] pr-[5px]">{transStatus ? <CircularProgress color='inherit' size={20} /> : '+'}</span> Invite
                    </button>
                </div>
            </Modal>}
        </div>
    );
}

export default PatientChart;

// function Status (props) {
//     const [Get, setGet] = useState(true);
//     return (
//         <div className='flex'><select
//             onChange={(e) => {
//                 // const temp = props.PatientData;
//                 // temp[props.i].status = e.target.value;
//                 // props.setPatientData(temp);
//                 setGet(!Get);
//             }} className={`outline-none border-none rounded-[6px] w-[124px] h-[27px] px-[5px] ${props.value === 'Upcoming' ? 'bg-[rgba(0,121,188,0.15)] text-[#016095]' : 'bg-[rgba(4,204,0,0.15)] text-[#038600]'}`} value={props.value}>
//             <option className='bg-[rgba(0,121,188,0.15)]  text-[#016095]' value={'Upcoming'}>Upcoming</option>
//             <option className='bg-[rgba(4,204,0,0.15)] text-[#038600]' value={'Complete'}>Complete</option>
//         </select></div>
//     );
// }

// function Actions (props) {
//     return (
//         <div className='flex'><img src={ADetails} className='w-[44px] h-[44px] max-[1600px]:w-[30px] max-[1600px]:h-[30px]' onClick={() => props.setDet(true)}/></div>
//     );
// }
